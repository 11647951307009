<template>
    <div class="actual-deal-panel hide-mobile" v-if="showPanel">
        <div class="container">
            <div class="actual-deal-panel-body">
                <div v-if="actualDeal" class="actual-deal">
                    <div
                        v-if="actualDealCarImageBgStyle"
                        class="actual-deal-car-image"
                        :style="actualDealCarImageBgStyle"
                    />
                    <div class="actual-deal-text">
                        Ваша текущая сделка
                    </div>
                    <router-link :to="actualDealRouteLink">
                        <button class="small primary">
                            Перейти
                        </button>
                    </router-link>
                </div>
                <div v-else class="other-deals">
                    <router-link to="/profile/reservations">
                        <button class="small primary">
                            <img v-lazy-load src="@/assets/img/icons/other-deals.svg" alt="">
                            <span>Мои заявки</span>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const actualDealFetchExpireMinutes = 2;

export default {
    name: "ActualDealPanel",
    fetch() {
        if (this.$auth.loggedIn && this.isFetchExpired && !this.$media.md) {
            return this.getActualDeal().then((data) => {
                if (this.actualDeal && isNaN(parseInt(this.actualDeal?.id))) {
                    this.$sentry.captureMessage(JSON.stringify(this.actualDeal));
                    this.$sentry.captureException(new Error(`Actual Deal invalid: ${this.actualDeal?.id}`));
                }
                return data;
            })
        }
    },
    computed: {
        isFetchExpired() {
            const timestamp = this.actualDealLastFetchTimestamp;
            if (timestamp) {
                const expiredDate = this.$moment().subtract(actualDealFetchExpireMinutes, 'minutes');
                return this.$moment(timestamp).isBefore(expiredDate);
            }
            return true;
        },
        showPanel() {
            return this.$auth.loggedIn;
        },
        actualDealRouteLink() {
            if (this.actualDeal) {
                return {
                    name: 'deal-dealId-settings',
                    params: {
                        dealId: this.actualDeal.id,
                    },
                };
            }
        },
        actualDealCarImageBgStyle() {
            if (this.actualDeal) {
                const carImageObject = this.actualDeal.car?.images?.[0];
                const carImage = carImageObject?.sm ?? carImageObject;
                if (typeof carImage === 'string') {
                    return {
                        'background-image': `url(${carImage})`,
                    };
                }
            }
        },
        ...mapState('profile', ['actualDeal', 'actualDealLastFetchTimestamp', 'pending']),
    },
    methods: {
        ...mapActions('profile', ['getActualDeal']),
    },
}
</script>

<style lang="scss">
.actual-deal-panel {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 5px;

    background: $blackBlue;

    .actual-deal-panel-body {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        button.small {
            min-width: 98px;
            padding: 0 12px;
            font-weight: 400;

            img {
                margin-right: 8px;
            }
        }

        a {
            text-decoration: none;
        }

        .actual-deal {
            display: flex;
            align-items: center;

            .actual-deal-car-image {
                width: 36px;
                height: 36px;
                margin-right: 16px;
                flex-shrink: 0;

                border-radius: 50%;
                border: 1px solid rgba($white, 0.5);
                background-color: $white;
                background-position: center;
                background-size: cover;

                @include mobile {
                    display: none;
                }
            }

            .actual-deal-text {
                margin-right: 16px;

                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $white;
            }
        }
    }
}
</style>
