<template>
    <BaseLayout show-join-block>
        <div class="page-index">
            <ActualDealPanel />
            <section class="head">
                <div class="container">
                    <div class="titles">
                        <h1>Маркетплейс аренды автомобилей</h1>
                        <h5>Мы&nbsp;объединили 2000+ предложений прокатов и&nbsp;частных автовладельцев по&nbsp;всей
                            России</h5>
                    </div>
                    <div class="head-filters">
                        <main-page-filters />
                    </div>
                    <car-categories-module @select-category="$signals.segmentsClickSegment" />
                </div>
            </section>
            <div v-if="(cars.recent && cars.recent.length) || $fetchState.pending" class="page-module">
                <div class="container">
                    <new-cars-module :list="cars.recent" :pending="$fetchState.pending" />
                </div>
            </div>
            <div class="main-modules-group page-module">
                <div class="container">
                    <div class="main-modules-group-content">
                        <our-advantages-module />
                        <media-posts-module />
                        <OurPartners />
                        <news-post-module :list="posts" />
                        <trip-topics-module :list="selections.route" />
                    </div>
                </div>
            </div>
            <div v-if="(cars.popular && cars.popular.length) || $fetchState.pending" class="new-car-section">
                <div class="container">
                    <popular-cars-module :list="cars.popular" :pending="$fetchState.pending" />
                </div>
            </div>
            <modal-prices />
        </div>
        <ClientOnly>
            <BonusPointsActivateModal
                v-show="promotion"
                :promotion="promotion"
                :segment-id="segmentId"
                @activated="resetPromoState"
                @click-close="onPromoModalClosed"
            />
            <BonusPointsExpiredPromotionModal @click-close="onPromoModalClosed" />
            <NewUsersPromotionRegistrationModal />
        </ClientOnly>
    </BaseLayout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { metaMerge, titleAndDescription } from "@/utils/meta";
import ModalPrices from "@/components/landing/modalPrices";
import { schemaCompany, schemaOrg } from "@/utils/schemaOrg";
import MediaPostsModule from "@/components/mainPage/modules/media/MediaPostsModule";
import NewsPostModule from "@/components/mainPage/modules/news/NewsPostModule";
import TripTopicsModule from "@/components/mainPage/modules/trip/TripTopicsModule";
import NewCarsModule from "@/components/mainPage/modules/NewCarsModule";
import PopularCarsModule from "@/components/mainPage/modules/PopularCarsModule";
import CarCategoriesModule from "@/components/mainPage/modules/carCategories/CarCategoriesModule";
import MainPageFilters from "@/components/mainPage/modules/filter/MainPageFilters";
import ActualDealPanel from "@/components/deal/ActualDealPanel";
import BaseLayout from "@/layouts/baseLayout";
import { replaceQuery } from '@/utils/router';
import BonusPointsActivateModal from "@/components/_elements/BonusPointsActivateModal";
import BonusPointsExpiredPromotionModal from "@/components/_elements/BonusPointsExpiredPromotionModal";
import OurPartners from "@/components/landing/OurPartners";
import NewUsersPromotionRegistrationModal from "@/components/_elements/NewUsersPromotionRegistrationModal";
import OurAdvantagesModule from '@/components/mainPage/modules/advantages/OurAdvantagesModule';

export default {
    name: "index",
    components: {
        OurAdvantagesModule,
        NewUsersPromotionRegistrationModal,
        OurPartners,
        BonusPointsExpiredPromotionModal,
        BonusPointsActivateModal,
        BaseLayout,
        ActualDealPanel,
        MainPageFilters,
        CarCategoriesModule,
        PopularCarsModule,
        NewCarsModule,
        TripTopicsModule,
        NewsPostModule,
        MediaPostsModule,
        ModalPrices,
    },
    fetch() {
        return this.$store.dispatch('landing/getLanding').then(
            () => ({}),
            (e) => this.$axiosError(this.$notifySrvErr('Ошибка получения данных от сервера', e)),
        );
    },
    fetchOnServer: false,
    head: metaMerge(
        titleAndDescription(
            'Аренда 🚗 авто без водителя от частных автовладельцев и прокатов в РФ - RentRide',
            'Арендуй 🚗 автомобиль для поездок за город и путешествий в Москве, Санкт-Петербурге и других городах РФ. ➔ Более 2000 машин. Условия проката: ✓ без водителя, ✓ без залога, ✓ без ограничений пробега, ✓ с доставкой, ✓ с КАСКО.',
            '/',
        ),
    ),
    jsonld() {
        return schemaOrg('LocalBusiness', {
            additionalType: 'AutoRental',
            ...schemaCompany,
        });
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('cars/cleanFilter');
        });
    },
    computed: {
        cars() {
            return this.landing?.settings?.cars || {};
        },
        selections() {
            return this.landing?.settings?.selections || {};
        },
        posts() {
            const blogPosts = this.landing?.settings?.posts?.last || [];
            const newsPosts = this.landing?.settings?.news?.last || [];
            return [
                ...blogPosts.map(post => ({ ...post, postType: 'blog' })),
                ...newsPosts.map(post => ({ ...post, postType: 'news' })),
            ];
        },
        ...mapState('landing', ['landing']),
        ...mapState('promotions', ['promotion', 'segmentId']),
    },
    watch: {
        '$route.query.promotionSegmentId': {
            immediate: true,
            handler(id) {
                if (id) {
                    this.setSegmentId(id);
                    this.getPromotion({ params: { id } })
                        .then(() => {
                            this.openModal(this.$modals.BONUS_POINTS_ACTIVATE);
                        })
                        .catch((e) => {
                            if (e.statusCode === 470) {
                                this.openModal(this.$modals.BONUS_POINTS_EXPIRED_PROMOTION);
                            } else if (e.m) {
                                this.$notifySrvErr(e.m);
                            }
                        });
                }
            },
        },

    },
    mounted() {
        if (this.$route.query.notFound === 'car') {
            this.$notify('Запрошенное ТС не найдено, удалено или скрыто владельцем', 'Не найдено', 'warning', null, 10000);
            replaceQuery(this, 'notFound');
        }
    },
    methods: {
        resetPromoState() {
            this.setSegmentId(null);
            this.setPromotion(null);
            if (this.$route.query.promotionSegmentId) {
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        promotionSegmentId: undefined,
                    },
                });
            }
        },
        onPromoModalClosed() {
            this.$signals.bonusPointsPromotionCloseModal(this.promotion?.id);
            this.resetPromoState();
        },
        ...mapMutations('modal', ['openModal']),
        ...mapMutations('promotions', ['setPromotion', 'setSegmentId']),
        ...mapActions('promotions', ['getPromotion']),
    },
}
</script>

<style lang="scss">
.page-index {

    .index-title {
        text-align: center;
        letter-spacing: -0.02em;

        @at-root .page-index h2.index-title {
            font-weight: 800;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;

            @include mobileUp {
                font-size: 48px;
                line-height: 64px;
            }
        }

        @at-root .page-index h5.index-title {
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.02em;
            color: $grayText;
            margin-bottom: 24px;

            @include mobileUp {
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 48px;
            }
        }
    }

    button.index-action {
        display: flex;
        margin: 16px auto 0;
        font-size: 14px;
        height: 40px;

        @include mobileUp {
            margin-top: 64px;
        }
    }

    .page-module {
        padding: 40px 0;

        @include mobileUp {
            padding: 80px 0;
        }
    }

    .page-sub-module {

        & + .page-sub-module {
            margin-top: 40px;

            @include mobileUp {
                margin-top: 80px;
            }
        }
    }

    .main-modules-group {
        background-color: $white;
        margin-bottom: 40px;

        @include mobileUp {
            margin-bottom: 80px;
        }

        .main-modules-group-content {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 40px;

            @include mobileUp {
                grid-gap: 80px;
            }
        }
    }

    .new-car-section {
        @include mobile {
            margin-bottom: 24px;
        }
    }

    section.dark {
        background: $blackBlue;
        color: $white;

        h5.index-title {
            color: $primaryDisabled;
        }
    }

    section.dark, section.padding {
        padding: 40px 0;

        @include mobileUp {
            padding: 80px 0;
        }
    }

    section.head {
        background-color: $white;
        padding: 24px 0 40px;
        position: relative;

        @include mobileUp {
            padding: 80px 0;
        }

        .titles {
            margin-bottom: 24px;
            padding: 0 8px;

            @include mobileUp {
                padding: 0;
                margin-bottom: 40px;
            }

            h1 {
                margin-bottom: 8px;

                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                letter-spacing: -0.01em;
                color: $blackBlue;

                @include mobileUp {
                    font-size: 48px;
                    line-height: 56px;
                    text-align: center;
                    letter-spacing: -0.03em;
                }
            }

            h5 {
                margin-bottom: 0;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $blackBlue;
                text-align: center;

                @include mobileUp {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.01em;
                }
            }
        }

        .head-filters {

        }
    }

    section.popular-car-list {

    }

    section.cars-lists {
        padding-bottom: 48px;

        @include mobileUp {
            padding-bottom: 120px;
        }

        .car-small-list {
            @include mobileUp {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        .head-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 25px;
            margin-top: 48px;

            @include mobileUp {
                flex-direction: row;
                align-items: center;
                margin-bottom: 40px;
                margin-top: 80px;
            }

            h2 {
                font-weight: 800;
                font-size: 24px;
                line-height: 32px;
                margin: 0;
                text-align: center;

                @include mobileUp {
                    font-size: 40px;
                    line-height: 48px;
                }
            }

            a {
                font-size: 14px;
                line-height: 24px;
                color: $primary;

                @include mobileUp {
                    font-weight: bold;
                    font-size: 18px;
                }
            }
        }
    }

    section.classes-cars {

    }
}
</style>
