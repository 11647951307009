import { mixinModelFilter, modelFilter } from "@/mixins/cars/modelFilters";

export default {
    mixins: [mixinModelFilter],
    props: {
        glossary: String,
        any: {default: "Любая"},
        listValues: {},
        parent: String,
        disabledAliasList: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        list(){
            let glossaryList = this.listValues || this.$store.getters['glossary/getGlossaryCarList'](this.glossary);
            if(this.filterList) glossaryList = glossaryList.filter(this.filterList);
            if(this.parent) glossaryList = glossaryList.filter((el) => el[this.parentKey] === this.parentVal);
            glossaryList = glossaryList.filter(({alias}) => !this.disabledAliasList.includes(alias));
            const list = [...(glossaryList || [])];
            if(this.any) {
                return [
                    { title: this.any, alias: null },
                    ...list,
                ];
            }
            return list;
        },

        parentVal() {
            if (this.parent) {
                const list = this.$store.state.glossary.glossariesCar[this.parent];
                if (list) {
                    const filters = this.$store.state.cars.filters;
                    return list.find(({ alias }) => alias === filters[this.parent])?.id;
                }
            }
        },
        parentKey() {
            return `${this.parent}_id`;
        },
        modelFilter() {
            return modelFilter(this.glossary);
        },
        value: {
            get(){
                return this.modelFilter.get.call(this);
            },
            set(val){
                this.modelFilter.set.call(this, val);
            }
        },
    },
    watch: {
        parentVal() {
            this.value = undefined;
        },
    },
}
