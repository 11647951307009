<template>
    <button class="bordered round favourite d-none">
        <img v-lazy-load src="@/assets/img/icons/car-like.svg" alt="">
    </button>
</template>
<script>
    export default {
        name: 'favourite-btn',
        props: ['car']
    }
</script>

<style lang="scss">
    button.favourite {
        height: 40px;
        width: 40px;
        border-radius: 50%;

        position: absolute;
        z-index: 2;
        top: 16px;
        right: 16px;

    }
</style>
