import {getRouterParams} from "@/utils/filters";

export default {
    methods: {
        resetFilters(filters){
            this.$store.commit('cars/cleanFilter', filters);
            this.$router.replace(getRouterParams(this.$store.state.cars.filters, this.$route)).catch(()=>{});
        },
    }
}
