<template>
    <div class="ShimmerLoaderCatalogCars" :class="{'ShimmerLoaderCatalogCars--small': small}">
        <div class="ShimmerLoaderCatalogCars__item" v-for="n in length" :key="n">
            <ShimmerLoaderWrap>
                <div class="ShimmerLoaderCatalogCars__image" />
                <div class="ShimmerLoaderCatalogCars__body">
                    <div class="ShimmerLoaderCatalogCars__group">
                        <div class="ShimmerLoaderCatalogCars__line" />
                        <div class="ShimmerLoaderCatalogCars__line" v-if="!small" />
                    </div>
                    <div class="ShimmerLoaderCatalogCars__group">
                        <div class="ShimmerLoaderCatalogCars__line" v-if="small" />
                        <div class="ShimmerLoaderCatalogCars__line"/>
                        <div class="ShimmerLoaderCatalogCars__line"/>
                    </div>
                </div>
            </ShimmerLoaderWrap>
        </div>
    </div>

</template>

<script>
import ShimmerLoaderWrap from "@/components/_elements/ShimmerLoaderWrap";

export default {
    name: "ShimmerLoaderCatalogCars",
    components: {ShimmerLoaderWrap},
    props: {
        length: {
            type: Number,
            default: 9,
        },
        small: Boolean,
    },
}
</script>

<style lang="scss">
.ShimmerLoaderCatalogCars {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;

    @include tablet() {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }

    @include mobile() {
        @include outContainer;

        grid-template-columns: 1fr;
        grid-gap: 8px;

        background: $shimmerBase;
    }

    &--small {
        grid-template-columns: repeat(4, 1fr);
        background: transparent;

        @include mobile {
            grid-template-columns: 1fr;
            margin: 0;
        }
    }

    &--small & {
        @include mobile {
            &__item:nth-child(n+2) {
                display: none;
            }
        }

        &__image {
            height: 174px;
        }

        &__group:nth-child(2) {
            .ShimmerLoaderCatalogCars__line {
                width: 208px;
                height: 20px;

                &:last-child {
                    width: 144px;
                    height: 28px;
                }
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;

        overflow: hidden;
        padding: 6px;

        border-radius: 12px;
        background: $shimmerBase;
    }

    &__image {
        height: 220px;
        border-radius: 8px;
        background: $shimmerAccent;
    }

    &__body {
        padding: 10px;
    }

    &__group {

        & + & {
            margin-top: 12px;
            padding-top: 12px;
            border-top: 1px solid rgba($blackBlue, 0.06);
        }

        &:nth-child(1) {
            .ShimmerLoaderCatalogCars__line {
                &:nth-child(1) {
                    width: 204px;
                    height: 24px;
                }

                &:nth-child(2) {
                    width: 122px;
                    height: 22px;
                }
            }
        }

        &:nth-child(2) {
            .ShimmerLoaderCatalogCars__line {
                width: 144px;
                height: 28px;

                &:last-child {
                    width: 208px;
                    height: 20px;
                }
            }
        }
    }

    &__line {
        background: $shimmerAccent;
        border-radius: 4px;

        & + & {
            margin-top: 6px;
        }
    }
}
</style>
