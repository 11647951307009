<template>
    <div class="our-advantages-item-module">
        <div class="our-advantages-item-module__icon">
            <img v-lazy-load :src="icon">
        </div>
        <div class="our-advantages-item-module__title" v-html="title"></div>
        <div class="our-advantages-item-module__about" v-html="about"></div>
    </div>
</template>

<script>

export default {
    name: "OurAdvantagesItemModule",
    props: {
        icon: String,
        title: String,
        about: String,
    },
}
</script>

<style lang="scss">
.our-advantages-item-module {
    background: #F3F4F7;
    border-radius: 12px;
    padding: 32px 20px 28px 20px;
    flex-shrink: 0;
    width: 100%;

    @include mobile {
        padding-right: 30px;
        min-height: 296px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;

        background: linear-gradient(134.91deg, #478CFE -5.93%, #9345FA 97.53%);
        border-radius: 100%;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.02em;
        margin-bottom: 12px;
    }

    &__about {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}
</style>
