<template>
    <div class="media-posts-module">
        <module-title title="СМИ пишут про нас"/>
        <div class="media-posts-list">
            <media-post-preview
                v-for="(media, index) in mediaList"
                :key="index"
                v-bind="media"
                @click.native="$signals.externalMediaClickArticle(media.name)"
            />
        </div>
    </div>
</template>

<script>
import ModuleTitle from "@/components/mainPage/ModuleTitle";
import MediaPostPreview from "@/components/mainPage/modules/media/MediaPostPreview";

export default {
    name: "MediaPostsModule",
    components: {MediaPostPreview, ModuleTitle},
    data: () => ({
        mediaList: [
            {
                name: 'VC.RU',
                logo: 'vc-ru-xs.svg',
                smallLogo: true,
                image: 'vc-ru.jpg',
                link: 'https://vc.ru/tribuna/458333-my-vzyali-amerikanskuyu-ideyu-i-sdelali-pervyy-v-rossii-rabotayushchiy-servis-po-arende-avto-u-chastnikov',
                title: 'Мы взяли американскую идею и сделали первый в России работающий сервис по аренде авто у частников',
            },
            {
                name: 'За рулем',
                logo: 'zarulem-xs.png',
                image: 'zarulem.jpg',
                link: 'https://www.zr.ru/content/news/925728-plyusy-i-minusy-narodnogo-karsh/',
                title: 'Хочу заработать, сдав авто в каршеринг. Можно?',
                description: 'Сколько может стоить частный автомобиль в аренду, можно ли будет торговаться, какие гарантии возможны и сколько на этом можно заработать? На эти вопросы уже есть ответы.',
            },
            {
                name: 'RBK',
                logo: 'rbk-xs.svg',
                smallLogo: true,
                image: 'rbk.jpg',
                link: 'https://trends.rbc.ru/trends/sharing/5dd5529e9a7947143779dea4',
                title: 'Миллионер из хрущоб: как изменить социальный статус благодаря шерингу',
            },
            {
                name: 'Lenta',
                logo: 'lenta-xs.svg',
                image: 'lenta.jpg',
                link: 'https://lenta.ru/articles/2018/04/17/rent_car_2',
                title: 'Предоставляем автомобиль по требованию',
                description: 'Как взять машину напрокат у частного лица и не попасть в передрягу',
            },
        ]
    }),
}
</script>

<style lang="scss">
.media-posts-module {
    .media-posts-list {
        display: grid;
        grid-gap: 4px;

        border-radius: 8px;
        overflow: hidden;

        @include mobileUp {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 24px;
            border-radius: 0;
        }

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
</style>
