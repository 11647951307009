<template>
    <div class="car-block" :class="{collection}" :id="car.id" v-if="car">
        <car-link :force-brand-alias="forceBrandAlias" :car="car" :openWindow="isOpenWindow" class="slider-link">
            <favourite-btn/>
            <slider :images="images" :car="car" />
        </car-link>

        <div class="info-car">
            <car-link :force-brand-alias="forceBrandAlias" :car="car" :openWindow="isOpenWindow">
                <title-car :car="car"/>
            </car-link>

            <params :values="car.options" :options="car.main_options" v-if="car"/>

            <prices-car :car="car" :period="period"/>

            <div class="car-footer">
                <slot name="actions">
                    <div class="actions" v-if="collection">
                        <car-link :force-brand-alias="forceBrandAlias" :car="car" :openWindow="isOpenWindow">
                            <button class="primary">Показать {{ proposalsWord }}</button>
                        </car-link>
                    </div>
                    <div class="actions" v-else>
                        <car-link :force-brand-alias="forceBrandAlias" :car="car" :openWindow="isOpenWindow">
                            <button class="gray">Забронировать</button>
                        </car-link>
                    </div>

                    <car-rating class="small-mobile" :rating="car.rating"/>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Params from "@/components/cars/_car/params";
import car from "@/mixins/cars/car";
import noImages from '@/assets/img/car/car-no-image.svg'
import CarLink from "@/components/cars/_car/carLink";

export default {
    name: 'car',
    mixins: [car],
    components: {
        CarLink,
        Params,
    },
    props: {
        forceBrandAlias: String,
    },
    data: () => ({
            noImages
        }),
        computed: {
            images() {
                return this.car.images && this.car.images.length
                    ? this.car.images
                    : [this.noImages];
            },
        }
    }
</script>

<style lang="scss">
    .car-block {
        margin-bottom: 8px;
        position: relative;
        padding: 16px;
        background: $white;
        box-shadow: 0px 1px 2px rgba(5, 47, 95, 0.04);

        @include tabletUp {
            display: grid;
            grid-template-columns: 1fr 400px;
            grid-column-gap: 32px;
            margin: 0;
            padding: 24px 40px;
            border-bottom: 1px solid $grayBorder;
            box-shadow: none;

            .slider-link {
                order: 2;
            }
        }

        .slider-car, .slider-car img {
            height: 180px !important;

            @include tabletUp {
                height: 258px !important;
            }
        }

        .info-car {
            position: relative;

            @include tabletUp {
                padding-right: 16px;
            }

            .car-info-price {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .car-footer {
                display: flex;
                align-items: center;
                margin: 10px 0 0 0;

                @include mobileUp {
                    margin-top: 26px;
                }
            }

            .actions {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include mobileUp {
                    width: auto;
                    flex-direction: row;
                }

                a {
                    flex-shrink: 2;
                    text-decoration: none;

                    @include mobileUp {
                        width: auto;
                    }
                }

                button {
                    height: 52px;
                    width: 100% !important;

                    @include mobileUp {
                        width: auto !important;
                    }
                }

                a + a {

                    margin-top: 16px;

                    @include mobileUp {

                        margin-left: 12px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
</style>
