import { mapMutations, mapState } from "vuex";
import { getModel } from "@/utils/vue";
import { getRouterParams } from "@/utils/filters";

export const mixinModelFilter = {
    computed: {
        ...mapState('cars', ['filters']),
    },
    methods: {
        ...mapMutations('cars', ['setFilter']),
    },
};

const modelGenerator = getModel('filters', 'setFilter');
export const modelFilter = (name) => {
    const model = modelGenerator(name);
    return {
        get() {
            return model.get.call(this);
        },
        set(val) {
            setFilter.call(this, model, name, val);
        },
    };
};
export const filterSetGenerator = () => {
    return function (name, val) {
        setFilter.call(this, modelGenerator(name), name, val);
    };
};

function setFilter(model, name, val) {
    const oldVal = this.filters[name];
    console.log(val, oldVal);

    if (val !== oldVal) {
        model.set.call(this, val);
        this.$router.replace(getRouterParams(this.filters, this.$route)).catch((e) => {
            console.warn('Filter change route err', e);
        });
        this.$signals.filterSetFilter(name, val);
    }
}
