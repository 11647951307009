<template>
    <div class="filter-item-header" :class="{'filter-item-header--input': input}">
        <div class="filter-value" v-if="value">
            {{ value }}
        </div>
        <div class="filter-placeholder" v-else>
            {{ placeholder }}
        </div>
        <slot>
            <img v-lazy-load src="@/assets/img/icons/filter-block-arrow.svg" alt="" loading="lazy">
        </slot>
    </div>
</template>

<script>
export default {
    name: "FilterItemHeader",
    props: {
        placeholder: String,
        value: String,
        icon: String,
        input: Boolean,
    },
}
</script>

<style lang="scss">
.filter-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 48px;
    padding-left: 16px;
    padding-right: 12px;

    background: #f2f4f8;
    cursor: pointer;

    @include mobileUp {
        height: 60px;

        padding-left: 20px;
        padding-right: 18px;
    }

    &--input {
        border-radius: 8px;

        @include mobileUp {
            height: 48px;
        }
    }

    .filter-placeholder {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: rgba(23, 35, 53, 0.64);
    }

    .filter-value {
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #172335;
    }
}
</style>
