<template>
    <div class="selection-item">
        <div @click="toSection" class="image-holder" v-lazy-background :lazy-background="bg"></div>
        <div class="selection-content">
            <router-link tag="h2" :to="link" class="name">{{selection.title}}</router-link>

            <div class="selection-price">
                <div class="title">
                    <span>Стоимость от:</span>
                </div>
                <div class="price">
                    <div class="val">₽ {{selection.min_price}}</div>
                    <div class="trait">/</div>
                    <div class="period">В сутки</div>
                </div>
            </div>
            <router-link :to="link">
                <button class="offer gray big">{{selection.cars_count}} предложения</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "selection-item",
        props: {
            selection: Object
        },
        computed: {
            bg(){
                if(this.selection?.images?.md) {
                    return this.selection.images.md;
                }
            },
            link(){
                if(!this.selection.alias) {
                    return {};
                }
                return {
                    name: 'selections-selection',
                    params: {
                        selection: this.selection.alias
                    }
                }
            }
        },
        methods: {
            toSection(){
                this.$router.push(this.link);
            }
        }
    }
</script>

<style lang="scss">
    .selection-item {
        background: $white;
        color: $blackBlue;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid rgba(23, 35, 53, 0.06);

        div.image-holder {
            background-image: url('~@/assets/img/_dev/selection-item.jpg');
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            width: 100%;
            height: 160px;
            cursor: pointer;

            @include mobileUp {
                height: 300px;
            }
        }

        .head {
            width: 100%;
            object-fit: cover;
            cursor: pointer;
        }

        .selection-content {
            padding: 24px;

            h2.name {
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
                letter-spacing: -0.02em;
                margin-bottom: 50px;
                cursor: pointer;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;

                @include mobileUp {
                    min-height: 64px;
                }
            }

            .selection-price {
                margin-bottom: 16px;

                .title {
                    display: flex;
                    align-items: center;

                    font-size: 11px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    color: $darkLight;
                    margin-bottom: 4px;

                    img {
                        margin-right: 7px;
                    }
                }

                .price {
                    display: flex;
                    align-items: flex-end;
                    letter-spacing: -0.06em;
                    color: $black;

                    .val {
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 40px;
                    }

                    .trait {
                        font-weight: 100;
                        font-size: 32px;
                        line-height: 40px;
                        color: #CACDD2;
                        margin: 0 6px;
                    }

                    .period {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }

            a {
                text-decoration: none;
            }

            button.offer {
                width: 100%;
                height: 48px;
                font-weight: bold;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: $blackLight;
            }
        }

        @include mobileUp {
            .selection-content {
                padding: 32px;

                h2.name {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 56px;
                }

                .selection-price {
                    margin-bottom: 16px;

                    .title {
                        margin-bottom: 6px;
                    }

                    .price {

                        .val {
                            font-size: 40px;
                            line-height: 48px;
                        }

                        .trait {
                            font-size: 40px;
                            line-height: 48px;
                        }
                    }
                }
            }
        }
    }
</style>
