<template>
    <div class="popular-cars-module">
        <module-nav-title title="Популярные автомобили" :link="{name: 'arendovat-city-filter-subfilter'}" />
        <car-preview-list
            :pending="pending"
            :is-mobile-slider="true"
            :cars-list="list || []"
            @car="$signals.popularCarsGoCar"
        />
    </div>
</template>

<script>
import ModuleNavTitle from "@/components/mainPage/ModuleNavTitle";
import CarPreviewList from "@/components/cars/_car/CarPreviewList";

export default {
    name: "PopularCarsModule",
    components: {CarPreviewList, ModuleNavTitle},
    props: {
        list: Array,
        pending: Boolean,
    }
}
</script>

<style lang="scss">
.popular-cars-module {

}
</style>
