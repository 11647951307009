<template>
    <div class="datepicker-title">
        <div class="prev" @click="prev">
            <img v-lazy-load src="@/assets/img/icons/date-picker-month-arrow.svg" alt="Назад">
        </div>
        <div class="titles-list" :style="monthsListStyles">
            <div
                class="current"
                v-for="monthNumber of numberOfMonthsToShow"
                :key="monthNumber"
                v-html="getMonthTitle(monthNumber)"
            />
        </div>
        <div class="next" @click="next">
            <img v-lazy-load src="@/assets/img/icons/date-picker-month-arrow.svg" alt="Вперёд">
        </div>
    </div>
</template>

<script>
import datePickerNumberOfMonth from "@/mixins/style/datePickerNumberOfMonth";

export default {
    name: 'datepicker-title',
    mixins: [datePickerNumberOfMonth],
    model: {
        prop: 'date',
        event: 'change'
    },
    props: {
        date: Number,
    },
    methods: {
        prev() {
            this.changeMonth(-1);
        },
        next() {
            this.changeMonth(1);
        },
        changeMonth(count) {
            this.$emit('change', +this.$moment(this.date).add(count, 'M'))
        },
        getMonthTitle(monthNumber) {
            return this.wrapMonthTitle(+this.$moment(this.date).add(monthNumber - 1, 'month'));
        },
        wrapMonthTitle(date) {
            const momentDate = this.$moment(date);
            const year = momentDate.format('YYYY');
            const month = momentDate.format('MMMM');
            return `<span class="month">${month}</span><span class="year">${year}</span>`
        },
    },
}
</script>

<style lang="scss">
    .datepicker-title {
        position: relative;
        display: flex;
        align-items: center;

        margin-bottom: 16px;

        .titles-list {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 40px;
            text-align: center;
        }

        .current {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: $blackBlue;

            .month {
                margin-right: 10px;

                @include mobile {
                    margin-right: 8px;
                }
            }

            .year {
                color: rgba($blackBlue, 0.24)
            }

            &:first-letter {
                text-transform: uppercase;
            }
        }

        .prev, .next {
            position: absolute;

            margin: -4px;
            padding: 4px;

            cursor: pointer;
            transition: 250ms opacity;

            &:hover {
                opacity: 0.6;
            }

            img {
                height: 24px;
            }
        }

        .prev {
            left: 0;
        }

        .next {
            right: 0;

            img {
                transform: rotate(180deg);
            }
        }
    }
</style>
