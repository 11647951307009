<template>
    <div class="car-categories-module-wrapper">
        <div class="car-categories-module" :class="{'car-categories-module--marketplace': marketplace}">
            <CarCategoryPreview
                v-for="(category, index) in list"
                :key="index"
                :lazy="index >= 2"
                v-bind="category"
                :marketplace="marketplace"
                @click.native.capture="emitCategoryClick(category)"
            />
        </div>
    </div>
</template>

<script>
import CarCategoryPreview from "@/components/mainPage/modules/carCategories/CarCategoryPreview";
import currentYear from '@/mixins/date/currentYear';
import { mapState } from "vuex";

export default {
    name: "CarCategoriesModule",
    components: { CarCategoryPreview },
    mixins: [currentYear],
    props: {
        marketplace: Boolean,
    },
    data: () => ({
        listRaw: [
            {
                name: "Эконом",
                price: "700 ₽ - 2999 ₽",
                img: '/img/classes/economy',
                filters: {
                    car_class: ['economy'],
                },
            },
            {
                name: "Комфорт",
                price: "3000 ₽ - 4999 ₽",
                img: '/img/classes/comfort',
                filters: {
                    car_class: ['standart'],
                },
            },
            {
                name: "Бизнес",
                price: "От 5000 ₽",
                img: '/img/classes/business',
                filters: {
                    car_class: ['business'],
                },
            },
            {
                name: "Premium",
                price: "От 9000 ₽",
                img: '/img/classes/premium',
                filters: {
                    car_class: ['premium'],
                },
            },
            {
                name: "Внедорожники",
                price: "От 1590 ₽",
                img: '/img/classes/suv',
                filters: {
                    price_from: 1500,
                    price_to: 500000,
                    cars_body: ['vnedorozhnik'],
                },
            },
            {
                name: "Минивэны",
                price: "От 1000 ₽",
                img: '/img/classes/minivan',
                filters: {
                    price_from: 1000,
                    price_to: 500000,
                    cars_body: ['miniven'],
                },
            },
            {
                name: "Уникальные авто",
                price: "От 4000 ₽",
                img: '/img/classes/unique',
                filters: {
                    price_from: 4000,
                    price_to: 500000,
                    tags: ['unikalnye'],
                },
            },
        ],
    }),
    computed: {
        list() {
            const ext = '.png';
            return this.listRaw.map((category) => ({
                ...category,
                img: category.img + ext,
                imgX2: category.img + '@2x' + ext,
                filters: this.fillCategoryFilters(category.filters),
            }));
        },
        ...mapState('cars', ['filters']),
    },
    methods: {
        fillCategoryFilters(filters) {
            const { date_from, date_to } = this.filters;
            return {
                date_from,
                date_to,
                ...filters,
            }
        },
        emitCategoryClick(category) {
            this.$emit('select-category', category.name)
        },
    },
};
</script>

<style lang="scss">
.car-categories-module {
    display: grid;
    margin-top: 48px;

    &--marketplace {
        @include mobile {
            margin: 4px 0 16px 0 !important;
        }
    }

    @include mobileUp {
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 24px;
        margin-top: 64px;
    }

    @include laptop {
        grid-gap: 16px;
    }

    @include tablet {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
