<template>
    <div class="OurPartners">
        <h2 class="OurPartners__title module-title">
            Наши партнеры
        </h2>
        <Hooper
            class="OurPartners__slider"
            :settings="sliderSettings"

        >
            <Slide
                class="OurPartners__item"
                v-for="(partner, index) in partners"
                :index="index"
                :key="index"
            >
                <OurPartnersItem v-bind="partner"/>
            </Slide>
            <Navigation v-show="!$media.md" slot="hooper-addons"/>
        </Hooper>
    </div>
</template>

<script>
import {Hooper, Slide, Navigation} from 'hooper';
import OurPartnersItem from "@/components/landing/OurPartnersItem";
import {mediaQueryBreakpoints} from "@/modules/mediaQueryPlugin";

export default {
    name: "OurPartners",
    components: {
        OurPartnersItem,
        Hooper, Slide, Navigation,
    },
    computed: {
        sliderSettings() {
            return {
                wheelControl: false,
                trimWhiteSpace: true,
                breakpoints: {
                    [mediaQueryBreakpoints.xl]: {itemsToShow: 5, itemsToSlide: 5},
                    [mediaQueryBreakpoints.lg]: {itemsToShow: 4, itemsToSlide: 4},
                    [0]: {itemsToShow: 2.5, itemsToSlide: 1},
                },
            }
        },
        partners() {
            return [
                {
                    title: 'Ренессанс',
                    link: 'https://www.renins.ru/',
                    logo: this.getPartnerLogo('renins.svg'),
                },
                /*                {
                                    title: 'Ингосстрах',
                                    link: '#',
                                    logo: this.getPartnerLogo('ingosstrakh.svg'),
                                },*/
                /*                {
                                    title: 'Югория',
                                    link: '#',
                                    logo: this.getPartnerLogo('ugsk.svg'),
                                },*/
                {
                    title: 'Совкомбанк',
                    link: 'https://sovcombank.ru/',
                    logo: this.getPartnerLogo('sovcombank.svg'),
                },
                {
                    title: 'Халва',
                    link: 'https://halvacard.ru/',
                    logo: this.getPartnerLogo('halva.svg'),
                },
                /*                {
                                    title: 'Тинькофф',
                                    link: '#',
                                    logo: this.getPartnerLogo('tinkoff.png'),
                                },*/
                {
                    title: 'Газпромнефть',
                    link: 'https://www.gazprom-neft.ru/',
                    logo: this.getPartnerLogo('gazprom.svg'),
                },
                /*                {
                                    title: 'Лукойл',
                                    link: '#',
                                    logo: this.getPartnerLogo('lukoil.png'),
                                },*/
                {
                    title: 'best2pay',
                    link: 'https://54.best2pay.ru/',
                    logo: this.getPartnerLogo('best2pay.png'),
                },
                {
                    title: 'Porsche ',
                    link: 'https://www.porsche.com/',
                    logo: this.getPartnerLogo('porsche.png'),
                },
                {
                    title: 'Audi',
                    link: 'https://www.audi.ru/',
                    logo: this.getPartnerLogo('audi.svg'),
                },
                {
                    title: 'Volkswagen',
                    link: 'https://www.volkswagen.ru/',
                    logo: this.getPartnerLogo('volkswagen.svg'),
                },
            ];
        },
    },
    methods: {
        getPartnerLogo(imageName) {
            try {
                return require(`@/assets/img/partners/${imageName}`);
            } catch (e) {
                return null;
            }
        }
    },
}
</script>

<style lang="scss">
.OurPartners {
    padding-bottom: 80px;

    @include mobile {
        padding-bottom: 30px;
    }

    &__title {
        margin-bottom: 52px;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &__slider.hooper {
        width: auto;
        margin: 0 -12px;

        @include laptopLarge {
            padding: 0 48px;
        }

        @include mobile {
            margin: 0 -15px;
            padding: 0;
        }

        &, .hooper-list, .hooper-track {
            height: auto;
        }
    }

    &__item.hooper-slide {
        height: auto;
        padding: 0 12px;
        opacity: 1 !important;

        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile {
            padding: 0 15px;
        }
    }

    .hooper-navigation {
        .hooper-prev,
        .hooper-next {
            box-shadow: 0 0.4px 1px rgba(11, 33, 65, 0.04), 0 12px 48px -8px rgba(11, 33, 65, 0.12);

            @include laptopLargeUp {
                width: 56px;
                height: 56px;
            }

            &.is-disabled {
                display: flex;

                svg {
                    opacity: 0.48;
                }
            }
        }

        .hooper-prev {
            left: 0;

            @include laptopLargeUp {
                margin-left: -80px;
            }
        }

        .hooper-next {
            right: 0;

            @include laptopLargeUp {
                margin-right: -80px;
            }
        }
    }
}
</style>
