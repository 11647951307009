<template>
    <filter-item-modal-holder filter-name="transmission">
        <template #header>
            <filter-item-header
                class="filter-left-side-block"
                :placeholder="!$media.md ? 'Коробка передач' : 'Коробка'"
                :value="value"
            />
        </template>
        <template #body>
            <client-only>
                <filter-list
                    glossary="transmission"
                    title="Выберите тип коробки"
                    v-if="!$media.md"
                />
                <filter-list-mobile glossary="transmission" v-else />
            </client-only>
        </template>
    </filter-item-modal-holder>
</template>

<script>
import FilterItemHeader from "@/components/mainPage/modules/filter/FilterItemHeader";
import FilterItemModalHolder from "@/components/mainPage/modules/filter/FilterItemModalHolder";
import FilterList from "@/components/search/filters/types/filterList";
import FilterListMobile from "@/components/search/filters/types/filterListMobile";
import {mapState, mapGetters} from 'vuex';

export default {
    name: "TransmissionFilterBlock",
    components: {FilterListMobile, FilterList, FilterItemModalHolder, FilterItemHeader},
    computed: {
        value() {
            const val = this.filters.transmission;
            if (val) {
                return this.getGlossaryOption("transmission", val)?.title;
            }
        },
        ...mapGetters("glossary", ["getGlossaryOption"]),
        ...mapState("cars", ["filters"]),
    },
}
</script>
