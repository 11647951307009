<template>
    <div class="modal-filter" :class="[{right}, id]">
        <slot name="header" :close="close">
            <div class="modal-header">
                <span class="title" v-if="title">{{ title }}</span>
                <img v-lazy-load src="@/assets/img/icons/reset.svg" alt="" @click="close">
            </div>
        </slot>
        <div class="modal-content">
            <slot :close="close"></slot>
        </div>
        <slot name="footer" :close="close">
            <div class="modal-footer">
                <button class="red big" @click="save">Применить</button>
                <clean-filters />
            </div>
        </slot>
    </div>
</template>

<script>

import CleanFilters from '@/components/cars/filters/cleanFilters';

export default {
    name: "modal-filter",
    components: { CleanFilters },
    props: {
        id: String,
        title: String,
        right: {
            default: false,
        },
    },
    methods: {
        save() {
            this.$emit('save');
        },
        reset() {
            this.$emit('reset');
        },
        close() {
            this.$emit('close');
        },
    },
}
</script>

<style lang="scss">
.modal-filter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    background: $white;
    max-height: 100vh;
    overflow-y: auto;
    $padding-modal: 16px;
    $padding-modal-pc: 40px;
    @include safeArea;

    &.button-fix {
        top: unset;
        box-shadow: 0px -4px 68px rgba(0, 0, 0, 0.15);
        border-radius: 12px 12px 0 0;
    }

    &.select {
        right: 0;

        .modal-content {
            padding: 8px 16px;

            .item {
                padding: 16px 8px;
                padding-right: 24px;
                line-height: 1;
                opacity: 0.9;
                cursor: pointer;

                &.active {
                    opacity: 1;
                    background: url("~@/assets/img/icons/option-active.svg") no-repeat right center;
                }

                &:hover {
                    opacity: 0.75;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $bgGrey;
                }
            }
        }
    }

    .modal-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $greyLine;
        padding: 24px $padding-modal;

        @include mobileUp {
            padding: 24px $padding-modal-pc;
        }

        img {
            height: 18px;
            cursor: pointer;
        }

        .clean {
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            color: $greyDark;
        }
    }

    .modal-content {
        min-height: max-content;
        flex-shrink: 2;
        border: none;
        border-radius: 0;
        padding: 20px $padding-modal;

        @include mobileUp {
            height: 100%;
            padding: $padding-modal-pc;
        }

        hr {
            display: none;
        }

        h2 {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.02em;
            color: $black;
            margin-bottom: 16px;
        }
    }

    .modal-footer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 16px 16px 24px;
        background: $white;

        @include mobileUp {
            padding: 20px 40px;
        }

        @include mobile {
            .clear-filters-button {
                margin-top: 12px;
                justify-content: center;
            }
        }

        button {
            width: 100%;
            font-size: 15px;
        }

        .clean-filters-block {
            margin-top: 24px;
        }
    }

    @include mobileUp {
        position: absolute;
        left: 0;
        top: 100%;
        bottom: unset;
        right: unset;
        margin-top: 8px;
        border-radius: 8px;

        @include block-shadow;

        .modal-header, .modal-footer {
            &:not(.pc-show) {
                display: none;
            }
        }

        &.term .modal-header {
            position: relative;
            z-index: 2;
            display: none;
            text-align: right;
            padding: 8px 10px 0 0;
            margin-bottom: -15px;
            border: none;

            img {
                display: none;
            }
        }

        .modal-content {
            max-height: calc(min(75vh, 500px));
            min-height: unset;
            overflow-y: auto;

            hr {
                display: block;
                margin-bottom: 32px;
            }
        }

        h2:not(.show-pc) {
            display: none;
        }

        &.button-fix {
            top: 100%;
            border-radius: 8px;

            h2 {
                display: block;
                font-size: 14px;
                line-height: 16px;
                color: rgba($black, 0.75);
            }

            .modal-content {
                padding: 16px 24px;
            }
        }

        &.right {
            left: unset;
            right: 0;
            width: 814px;
            max-width: 86vw;
        }
    }
}
</style>
