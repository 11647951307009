<template>
    <div class="datepicker-weekday" :class="{preview: previewMode}">
        <div
            class="weekday-item"
            v-for="weekday in weekdays"
            :key="weekday"
        >
            {{weekday}}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'datepicker-weekday',
        props: {
            previewMode: Boolean,
        },
        data(){
            const list = this.$moment.weekdaysMin();
            return {
                weekdays: [...list.slice(1), list[0]]
            }
        }
    }
</script>

<style lang="scss">
    .datepicker-weekday {
        display: flex;
        justify-content: space-between;

        margin-bottom: 12px;

        &.preview {
            .weekday-item {
                width: $previewModeDataPickerSizeDay;
                padding-left: 0;
                text-align: center;
            }
        }

        .weekday-item {
            width: $dataPickerSizeDay;
            padding-left: 8px;

            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.08em;
            color: rgba($blackBlue, 0.4);
            text-transform: uppercase;

            @include mobile {
                padding-left: 0;

                line-height: 24px;
                text-align: center;
            }

            &:nth-child(6), &:nth-child(7) {
                color: #F14B3C;
            }
        }
    }
</style>
