<template>
    <span v-if="disabled">
        <slot/>
    </span>
    <a v-else-if="openWindow" :href="getHref(route)" target="_blank" >
        <slot/>
    </a>
    <router-link :to="route" v-else>
        <slot/>
    </router-link>
</template>

<script>
export default {
    name: "carLink",
    props: {
        car: Object,
        openWindow: Boolean,
        forceBrandAlias: String,
        disabled: Boolean,
    },
    computed: {
        collection() {
            return this.car?.items;
        },
        route() {
            const city = this.$route.params.city || this.car?.city?.alias || this.$store.getters['glossary/currentCity'].alias;
            const filters = this.$store.state.cars.filters;
            if (this.collection) {
                return{
                    name: 'group-groupId-city',
                    params: {groupId: this.car.id, city},
                    query: {...filters}
                };
            } else {
                const {date_from: period_start, date_to: period_end} = {...this.$route.query, ...filters};
                const query = {period_start, period_end};
                if (this.forceBrandAlias === 'audi') {
                    query.template = 'audi';
                }
                return {
                    name: 'cars-id',
                    params: {id: this.car.id},
                    query,
                };
            }
        },
    },
    methods: {
        getHref(route){
            return this.$router.resolve(route).href;
        }
    }
}
</script>
