<template>
    <div v-if="list && list.length" class="NewsPostModule">
        <ModuleNavTitle :title="title" :link="{name: postType}"/>
        <ClientOnly>
            <div class="NewsPostModule__list" v-if="!$media.md">
                <NewsPostPreview
                    class="NewsPostModule__item"
                    v-for="post in listToShow"
                    v-bind="post"
                    :key="post.id"
                    :post-type="post.postType || postType"
                    @click.native="$signals.blogClickArticle(post.title)"
                />
            </div>
            <MobileSlider v-else :list="listToShow">
                <template #default="{item: post, index}">
                    <NewsPostPreview
                        v-bind="post"
                        :key="post.id || index"
                        :post-type="post.postType || postType"
                        @click.native="$signals.blogClickArticle(post.title)"
                    />
                </template>
            </MobileSlider>
        </ClientOnly>
    </div>
</template>

<script>
import ModuleNavTitle from "@/components/mainPage/ModuleNavTitle";
import MobileSlider from "@/components/mainPage/modules/MobileSlider";
import NewsPostPreview from "@/components/mainPage/modules/news/NewsPostPreview";

export default {
    name: "NewsPostModule",
    components: {NewsPostPreview, MobileSlider, ModuleNavTitle},
    props: {
        title: {
            type: String,
            default: 'Новости и статьи',
        },
        list: {
            type: Array,
            default: () => [],
        },
        postType: {
            type: String,
            default: 'blog',
            validate: (type) => ['blog', 'news'].includes(type),
        },
    },
    computed: {
        listToShow() {
            return this.list.slice(0, 5);
        },
    },
}
</script>

<style lang="scss">
.NewsPostModule {

    &__list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 24px;
    }

    &__item {
        grid-column: 2 span;

        &:nth-child(5n + 1),
        &:nth-child(5n + 2) {
            grid-column: 3 span;
        }
    }
}
</style>
