<template>
    <a
        class="media-post-preview"
        v-lazy-background :lazy-background="imageBg"
        :href="link"
        target="_blank"
    >
        <div class="media-post-preview-content">
            <div class="logo-holder" :class="{small: smallLogo}">
                <img v-lazy-load class="logo-image" :src="logoSrc" :alt="name" />
            </div>
            <div class="post-body">
                <div class="post-text-content">
                    <div class="post-title">
                        {{ title }}
                    </div>
                    <div class="post-description" v-if="description">
                        {{ description }}
                    </div>
                </div>
                <div class="more-details">
                    Подробнее
                    <img v-lazy-load
                        class="more-details-icon"
                        src="@/assets/img/icons/media-more-info.svg"
                        alt="more"
                    />
                </div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: "MediaPostPreview",
    props: {
        logo: String,
        smallLogo: Boolean,
        name: String,
        link: String,
        image: String,
        title: String,
        description: String,
    },
    computed: {
        logoSrc() {
            try {
                return require(`@/assets/img/logos/${this.logo}`);
            } catch (e) {
                return null;
            }
        },
        imageBg() {
            try {
                return require(`@/assets/img/media/postsBg/${this.image}`);
            } catch (e) {}
        },
    },
};
</script>

<style lang="scss">
.media-post-preview {
    position: relative;
    background-color: rgba(23, 35, 53, 0.88);
    background-size: cover;
    background-position: center;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-color: rgba(23, 35, 53, 0.88);
        transition: background-color 250ms;
    }

    &:hover {
        text-decoration: none;

        &::after {
            background-color: rgba(23, 35, 53, 0.72);
        }
    }

    @include mobileUp {
        border-radius: 8px;
        overflow: hidden;
    }

    .media-post-preview-content {
        position: relative;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        height: 100%;
        min-height: 140px;
        padding: 16px;

        @include mobileUp {
            padding: 20px;

            align-items: stretch;
        }
    }

    &:first-child,
    &:last-child {
        grid-column: 1 / 3;

        @include mobileUp {
            grid-column: auto;
        }

        .logo-holder .logo-image {
            @include mobile {
                min-width: 140px;
            }
        }
    }

    .logo-holder {
        margin-bottom: 12px;

        @include mobileUp {
            display: flex;
            align-items: center;

            height: 56px;
            margin-bottom: 0;
            padding-bottom: 16px;

            border-bottom: 1px solid rgba($white, 0.12);
        }

        &.small .logo-image {
            max-width: 100px;
        }

        .logo-image {
            width: 100%;
            max-height: 32px;
            max-width: 154px;
        }
    }

    .post-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mobileUp {
            padding-top: 16px;
            flex-grow: 1;
        }

        .post-text-content {
            display: none;

            @include mobileUp {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                padding-bottom: 20px;
            }

            .post-title {
                margin-bottom: 8px;

                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                color: $white;
            }

            .post-description {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: rgba($white, 0.64);
            }
        }

        .more-details {
            display: flex;
            justify-content: center;
            align-items: center;

            color: $white;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;

            transition: 250ms;

            @include mobileUp {
                height: 40px;
                padding: 0 16px;

                background-color: rgba(255, 255, 255, 0.24);
                border-radius: 6px;

                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.01em;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.36);
                }
            }

            .more-details-icon {
                @include mobileUp {
                    display: none;
                }
            }
        }
    }
}
</style>
