<template>
    <div class="pagination-number">
        <component
            :is="route ? 'router-link' : 'router-to-cars'"
            class="pagination-number-item"
            :class="{active: page === active, other: isOtherPages}"
            v-for="({page, isOtherPages}, i) in items"
            :key="i"
            :filters="{page}"
            :to="routePage(page)"
            :save-filters="true"
        >
            {{ page }}
        </component>
    </div>
</template>

<script>
import RouterToCars from "@/components/router/routerToCars";


export default {
    name: 'pagination-number',
    components: {RouterToCars},
    model: {
        prop: 'active',
        event: 'change'
    },
    props: {
        max: Number,
        active: Number,
        route: String,
    },
    computed: {
        count() {
          return !this.$media.md ? 9 : 6;
        },
        items() {
            const list = this.getList();
            const otherPages = {page: '...', isOtherPages: true};

            if (this.max > this.count) {
                const deleteCount = Math.min(this.active - 2, this.max - this.count);
                list.splice(0, deleteCount);
            }
            if (list.length > this.count) {
                const deleteCount = list.length - (this.count - 1);
                list.splice(this.count - 2, deleteCount, otherPages);
            }
            return list;
        },
    },
    methods: {
        routePage(page) {
            if (this.route) {
                const route = this.$router.match(this.route);
                route.query.page = page;
                return route;
            }
        },
        getList() {
            return Array.from({ length: this.max }).map((_, i) => ({
                page: i + 1,
            }));
        },
        setActive(page, i) {
            const n = parseInt(page);
            if (!Number.isNaN(n)) {
                this.$emit('change', page);
            } else {
                this.$emit('change', this.active + (i > 3 ? 3 : -3));
            }
        }
    }
}
</script>

<style lang="scss">
.pagination-number {
    display: flex;

    .pagination-number-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;

        background: none;
        border-radius: 50%;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        text-align: center;
        letter-spacing: -0.01em;
        text-decoration: none;
        color: rgba($blackBlue, 0.64);

        transition: 250ms;
        cursor: pointer;

        @include mobile {
            width: 40px;
            height: 40px;
        }

        &:hover {
            background-color: #E4E9F1;
            color: $blackBlue;
        }

        &.other {
            color: rgba($blackBlue, 0.24);
        }

        &.active {
            background-color: #5394FD;
            color: $white;
        }

        & + .pagination-number-item {
            margin-left: 16px;

            @include mobile {
                margin-left: 10px;
            }
        }
    }
}
</style>
