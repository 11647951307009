<template>
    <div
        v-if="active"
        class="AccentModal"
        @click.self="close"
    >
        <div class="AccentModal__body">
            <div class="AccentModal__close" @click="close">
                <img v-lazy-load src="@/assets/img/icons/close-accent-modal.svg" alt="">
            </div>
            <div v-if="title" class="AccentModal__title">
                {{ title }}
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
import modal from "@/mixins/modal/modal";

export default {
    name: "AccentModal",
    mixins: [modal],
    model: {
        prop: 'open',
        event: 'close',
    },
    props: {
        open: Boolean,
        title: String,
    },
    methods: {
        close() {
            this.$emit('click-close');
            this.closeModal();
        },
    },
}
</script>

<style lang="scss">
.AccentModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: rgba(8, 12, 18, 0.8);
    backdrop-filter: blur(8px);

    &__body {
        position: relative;
        display: flex;
        flex-direction: column;

        max-width: 360px;
        width: 100%;
        padding: 66px 28px 24px;
        overflow-y: auto;

        background: radial-gradient(85.33% 85.33% at 50% 50%, #2B1F61 19.59%, #211747 100%), #307EFD;

        @include mobile {
            justify-content: center;
            align-items: center;

            max-width: 100%;
            width: 100%;
            height: 100%;

            padding: 64px 28px;
        }
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        background: rgba($white, 0.12);
        border-radius: 50%;

        cursor: pointer;
        transition: 250ms;

        &:hover {
            background: rgba($white, 0.18);
        }
    }

    &__title {
        margin-bottom: 8px;

        text-align: center;
        font-weight: 800;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: $white;
    }
}
</style>
