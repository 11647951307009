<template>
    <RouterLink
        v-if="category"
        class="PostCategory"
        :to="link"
        :style="badgeColorStyle"
    >
        <div class="PostCategory__title">
            {{ category.title }}
        </div>
    </RouterLink>
</template>

<script>
import { paramsPosts } from "@/utils/router";

export default {
    name: "PostCategory",
    props: {
        category: Object,
        postType: String,
    },
    computed: {
        badgeColorStyle() {
            const color = this.category.color;
            if (color) {
                return { background: color }
            }
        },
        link() {
            return {
                name: `${this.postType}-category-category`,
                params: {
                    category: this.category.alias,
                },
                query: paramsPosts({
                    tag: this.$route.params.tag,
                }),
            }
        },
    },
}
</script>

<style lang="scss">
.PostCategory {
    display: inline-flex;
    padding: 8px 10px;

    background: rgba(15, 22, 36, 0.84);
    backdrop-filter: blur(5px);
    border-radius: 6px;

    &:hover {
        text-decoration: none;
        opacity: 0.9;
    }

    &__title {
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $white;
    }
}
</style>
