<template>
    <div class="filter-item-modal-holder" v-click-outside="close" ref="test">
        <div class="filter-item-holder-header" @click="toggle" tabindex="-1">
            <client-only>
                <slot name="header" />
            </client-only>
        </div>
        <div class="filter-item-holder-body" v-if="active || show">
            <client-only>
                <slot name="body" :close="close" />
            </client-only>
        </div>
    </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
    directives: { clickOutside: vClickOutside.directive },
    name: "FilterItemModalHolder",
    data: () => ({
        active: false,
    }),
    props: {
        filterName: String,
        show: Boolean,
    },
    methods: {
        close() {
            if (this.active) {
                this.active = false;
            }
        },
        toggle() {
            this.active = !this.active;
        },
    },
};
</script>

<style lang="scss">
.filter-item-modal-holder {
    height: 100%;
    position: relative;

    .filter-item-holder-header {
        height: 100%;
    }

    .filter-item-holder-body {
        position: relative;
        z-index: 5;
    }
}
</style>
