<template>
    <div class="trip-topics-module">
       <client-only>
           <module-nav-title :title="!$media.md ? 'Тема поездки' : 'Подборки'" :link="{name: 'selections'}"/>
           <div class="selections-list" v-if="!$media.md">
               <selection-item
                   v-for="selection in list"
                   :selection="selection"
                   :key="selection.id"
                   @click.native="$signals.travelThemeClickOpen(selection.title)"
               />
           </div>
           <mobile-slider :list="list" v-else>
               <template #default="{item: selection}">
                   <selection-item
                       :selection="selection"
                       :key="selection.id"
                       @click.native="$signals.travelThemeClickOpen(selection.title)"
                   />
               </template>
           </mobile-slider>
       </client-only>
    </div>
</template>

<script>
import SelectionItem from "@/components/selections/index/selection-item";
import ModuleNavTitle from "@/components/mainPage/ModuleNavTitle";
import MobileSlider from "@/components/mainPage/modules/MobileSlider";

export default {
    name: "TripTopicsModule",
    components: {MobileSlider, ModuleNavTitle, SelectionItem},
    props: {
        list: Array,
    }
}
</script>

<style lang="scss">
.trip-topics-module {

    .selections-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;

        @include mobileUp {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 24px;
        }
    }
}
</style>
