
<template>
    <div class="params-car" v-if="values">
        <div class="list">
            <div class="param" v-for="param in params" :key="param.name">
                <img v-lazy-load :src="param.icon" v-if="param.icon">
                <div class="info">
                    <div class="title">{{ param.name}}</div>
                    <div class="val">{{ param.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import params from "@/mixins/cars/params";

    export default {
        name: "params",
        mixins: [params]
    }
</script>


<style lang="scss">
    .params-car {
        margin-bottom: 40px;

        &.grid {
            width: 100%;

            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 26px 12px;
                justify-content: space-between;

                @include mobileUp {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include laptopUp {
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 32px;
                }
            }
        }

        &:not(.grid) {
           @include mobile {
               @include transparency_scrollable(36px);
               margin: 0 -16px;

               &::before {
                   width: 16px;
               }

               .list {
                   display: flex;
                   overflow: auto;
                   padding: 6px 4px;

                   .param {
                       padding: 0 16px;
                   }
               }
           }
        }

        .param {
            display: flex;
            align-items: center;
            line-height: 1;
            white-space: nowrap;

            img {
                margin-right: 12px;
            }

            .title {
                font-size: 8px;
                line-height: 14px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            .val {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        @include tabletUp {
            width: min-content;
            margin: 0 0 22px;

            &::after, &::before {
                display: none;
            }

            .list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 16px;
                overflow: visible;

                .param {
                    padding: 0;

                    .val {
                        font-size: 16px;
                    }
                }
            }
        }

        @include laptopUp {
            .list {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @include laptopLargeUp {
            .list {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 16px 40px;
            }
        }

    }
</style>
