<template>
    <div
        class="datepicker"
        :class="{'preview-mode': previewMode, 'multiple-months': numberOfMonths > 1}"
    >
        <DatepickerTitle v-model="date" :number-of-months="numberOfMonths"/>
        <div
            class="datepicker-content"
            :style="monthsListStyles"
        >
            <client-only>
                <DatepickerMonth
                    v-for="monthNumber in numberOfMonthsToShow"
                    :key="monthNumber"
                    :date="getMonthDate(monthNumber)"
                    :selected="period"
                    :previous="previous"
                    :validate-day="validateDay"
                    :is-single-date-picker="isSingleDatePicker"
                    :preview-mode="previewMode"
                    :colored-periods="coloredPeriods"
                    @select="select"
                />
            </client-only>
        </div>
    </div>
</template>

<script>
import DatepickerTitle from "@/components/_elements/datapicker/datepickerTitle";
import DatepickerMonth from "@/components/_elements/datapicker/DatepickerMonth";
import { DATEPICKER } from "@/utils/_consts";
import datePickerNumberOfMonth from "@/mixins/style/datePickerNumberOfMonth";

export default {
    name: "datapicker",
    mixins: [datePickerNumberOfMonth],
    components: {DatepickerMonth, DatepickerTitle},
    model: {
        prop: 'period',
        event: 'change'
    },
    props: {
        // is Number if 'isSingleDatePicker'
        period: {
            type: [Object, Number],
            default: () => ({
                start: null,
                end: null,
            }),
        },
        validateDay: {
            type: Function,
            default: () => true,
        },
        firstSelectStart: {
            type: Boolean,
            default: true,
        },
        isSingleDatePicker: Boolean,
        preventFirstSelectSwap: Boolean,
        previewMode: Boolean,
        allowPreviousDays: Boolean,
        coloredPeriods: Array,
    },
    data({firstSelectStart}) {
        return {
            previous: this.allowPreviousDays ? 0 : +this.$moment().startOf('day'),
            date: +this.$moment().startOf('month').set(DATEPICKER.default_time),
            selectingStart: firstSelectStart,
            isFirstSelect: true,
        }
    },
    watch: {
        period(period, oldPeriod) {
                if (this.isSingleDatePicker) {
                    if (period) {
                        this.$emit('selected');
                    }
                } else {
                    const {start, end} = period;
                    // Period not transformed
                    if ((start && end) && (start === oldPeriod.start || end === oldPeriod.end)) {
                        this.$emit('selected');
                    }
                }
            },
        },
        methods: {
            getMonthDate(monthNumber) {
                return +this.$moment(this.date).add(monthNumber - 1, 'month');
            },
            select(date) {
                if (this.isSingleDatePicker) {
                    return this.emitPeriod(date);
                }
                let {start, end} = this.period;
                if (this.selectingStart) {
                    start = date;
                    this.selectingStart = false;
                    this.$emit('select-start', start);
                } else {
                    end = date;
                    this.selectingStart = true;
                    this.$emit('select-end', end);
                }
                this.changePeriod({start, end});
            },
            changePeriod({start, end}) {
                const preventSwap = this.preventFirstSelectSwap && this.isFirstSelect;
                if (!preventSwap && (start && end && start > end)) {
                    [start, end] = [end, start];
                }
                this.isFirstSelect = false;
                this.emitPeriod({start, end});
            },
            emitPeriod(period) {
                this.$emit('change', period);
            },
        },
        created() {
            let date;
            if (this.isSingleDatePicker) {
                date = this.period;
            } else {
                const {start, end} = this.period;
                date = start || end;
            }
            if (date && !this.double) {
                this.date = +this.$moment(date).startOf('month').set(DATEPICKER.default_time);
            }
        },
    }
</script>

<style lang="scss">
    .datepicker {
        position: relative;
        z-index: 2;

        font-weight: 500;
        font-size: 12px;
        max-width: 100%;

        &.preview-mode.multiple-months {

            @include laptop {
                margin: 0 auto;
                width: fit-content;
            }

            .datepicker-content, .titles-list {
                grid-column-gap: 32px;

                @include laptopLarge {
                    grid-column-gap: 24px;
                }

                @include laptop {
                    grid-gap: 32px;
                    grid-template-columns: repeat(2, 1fr) !important;

                    & > * {
                        &:not(&:nth-child(1), &:nth-child(2)) {
                            display: none !important;
                        }
                    }
                }

                @include tablet {
                    grid-template-columns: 1fr !important;

                    & > * {
                        &:not(&:nth-child(1)) {
                            display: none !important;
                        }
                    }
                }
            }
        }

        .datepicker-content {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 40px;
            justify-items: center;
            padding: 0;
        }
    }
</style>

