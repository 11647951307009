<template>
    <modal-filter
        title="Укажите даты аренды"
        id="term"
        @reset="reset"
        @close="$emit('close')"
        @save="$emit('close')"
    >
        <template #default="{close}">
            <datepicker
                v-model="date"
                @selected="close"
                v-on="$listeners"
            />
        </template>
    </modal-filter>
</template>

<script>
import ModalFilter from "@/components/search/filters/_modal";
import Datepicker from "@/components/_elements/datapicker/datepicker";
import { mixinModelFilter, modelFilter } from "@/mixins/cars/modelFilters";
import { getModels } from "@/utils/vue";

export default {
    name: "filterTerm",
    components: { Datepicker, ModalFilter },
    mixins: [mixinModelFilter],
    computed: {
        date: {
            get() {
                return {
                    start: this.date_from,
                    end: this.date_to,
                };
            },
            set({ start, end }) {
                this.date_from = start;
                this.date_to = end;
            },
        },
        ...getModels(modelFilter, ['date_from', 'date_to']),
    },
    methods: {
        reset() {
            this.date = {};
        },
    },
}
</script>

<style lang="scss">
.modal-filter.term .datepicker {
    margin: 0 auto;
}
</style>
