<template>
    <modal :id="$modals.PRICES_SERVICES"
           class="modal-prices-services"
           title="Стоимость доставки зависит от кол-ва дней аренды"
           description="Услуга предоставляется в пределах МКАД" >
        <table>
            <thead>
                <tr>
                    <td>Аренда</td>
                    <td>Стоимость доставки или возврата</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1-3 суток</td>
                    <td>950 руб</td>
                </tr>
                <tr>
                    <td>4-7 суток</td>
                    <td>700 руб</td>
                </tr>
                <tr>
                    <td>8-14 суток</td>
                    <td>500 руб</td>
                </tr>
                <tr>
                    <td>от 15 суток</td>
                    <td>БЕСПЛАТНО</td>
                </tr>
            </tbody>
        </table>
    </modal>
</template>

<script>
import Modal from "@/components/_elements/modal";
export default {
name: "modalPrices",
    components: {Modal}
}
</script>

<style lang="scss">
    .modal-prices-services {
        .modal-header .modal-title {
            font-size: 24px;
            line-height: 1.25;
        }

        table {
            thead {
                font-weight: 500;
                td {
                    padding-bottom: 6px;
                }
            }
            td {
                padding: 4px 0;
            }
            td:first-child {
                padding-right: 32px;
                white-space: nowrap;
            }
            tbody td {
                border-top: 1px solid $grayBorder;
            }
        }
    }
</style>
