export function getBgImage(obj, prop) {
    const image = obj[prop];
    if (image) {
        return {
            'background-image': `url(/${image})`
        }
    }
}

export function validForm(form, ignored = []) {
    return Object.keys(form).every((key) => ignored.includes(key) || form[key]);
}

export let getModel = (state, mutation) => (name) => ({
    get() {
        return this[state][name];
    },
    set(val) {
        this[mutation]({name, val});
    }
});

export let getModels = (model, list) => {
    return list.reduce((obj, name) => {
        obj[name] = model(name);
        return obj;
    }, {});
};

export function fieldsError() {//Работает с this.error и собирает ошибки для полей в объект
    if (this.error && this.error.errors) {
        const errors = this.error.errors;
        return Object.keys(errors)
            .reduce((obj, key) => {
                obj[key] = errors[key].join(', ');
                return obj;
            }, {});
    } else return {};
}
