<template>
    <router-link :to="link" class="module-nav-title">
        <module-title class="module-nav-title-text" :title="title"/>
        <img v-lazy-load class="module-nav-title-arrow" src="@/assets/img/icons/nav-title-arrow.svg" alt="link" loading="lazy">
    </router-link>
</template>

<script>
import ModuleTitle from "@/components/mainPage/ModuleTitle";

export default {
    name: "ModuleNavTitle",
    components: {ModuleTitle},
    props: {
        title: String,
        link: [Object, String]
    }
}
</script>

<style lang="scss">
.module-nav-title {
    display: inline-flex;
    align-items: center;

    margin-bottom: 16px;

    @include mobileUp {
        margin-bottom: 24px;
    }

    &:hover {
        text-decoration: none;
    }

    .module-nav-title-text {
        margin-bottom: 0;
        margin-right: 6px;

        @include mobileUp {
            margin-right: 8px;
        }
    }

    .module-nav-title-arrow {
        width: 24px;

        @include mobileUp {
            width: 32px;
        }
    }
}
</style>
