<template>
    <div class="tooltip-wrapper" tabindex="-1" :class="{open: opened, wrapper}">
        <div
            class="tooltip-trigger"
            @click.prevent.stop.capture="toggle"
            @mouseenter.prevent="onHover && open()"
            @mouseleave.prevent="onHover && close()"
            v-click-outside="outside"
        >
            <slot>
                <span class="question-icon-holder" v-html="TooltipIcon" />
            </slot>
        </div>
        <div class="tooltip-content">
            <slot name="content">{{ text }}</slot>
        </div>
    </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import TooltipIcon from "@/assets/img/icons/tooltip.svg?raw"

export default {
    name: "tooltip",
    directives: { clickOutside: vClickOutside.directive },
    props: {
        text: String,
        disabled: Boolean,
        onHover: Boolean,
        wrapper: Boolean,
    },
    data: () => ({
        opened: false,
        TooltipIcon,
    }),
    computed: {
        outside() {
            return { capture: true, handler: this.close };
        },
    },
    methods: {
        toggle() {
            this.setOpened(!this.opened);
            this.$emit('click');
        },
        open() {
            this.setOpened(true);
        },
        close() {
            this.setOpened(false);
        },
        setOpened(status) {
            if (!this.disabled) {
                this.opened = status;
            }
        },
    },
}
</script>

<style lang="scss">
.tooltip-wrapper {
    display: inline-flex;
    outline: none;
    margin-left: 6px;

    @include mobileUp {
        position: relative;
    }

    @include mobile {
        display: inline;
    }

    &.wrapper {
        margin: 0;

        .tooltip-trigger {
            margin: 0;
            width: auto;
            height: auto;

            background: none !important;
            border: none !important;
        }
    }

    &.open {
        .tooltip-trigger {
            background: $blackBlue;
            border-color: $blackBlue;

            &:hover {
                background: $blackBlue;
            }

            .question-icon-holder path {
                fill: $white;
            }
        }

        .tooltip-content {
            display: flex;
        }
    }

    &.warm {
        .tooltip-trigger {
            background: $error !important;

            path {
                fill: $white;
            }
        }
    }

    &.left .tooltip-content {
        @include mobileUp {
            left: unset;
            right: 100%;
            margin: 0 16px 0 0;

            &::after {
                right: unset;
                left: 100%;
                transform: rotate(180deg) translateY(50%);
            }
        }
    }

    &.bottom .tooltip-content {
        @include mobileUp {
            left: unset;
            right: unset;
            top: 100%;
            margin: 16px 0 0 0;
            transform: translateX(calc(-50% + 10px));

            &::after {
                right: unset;
                top: unset;
                left: 50%;
                bottom: 100%;
                transform: translate(-50%, 40%) rotate(90deg);
            }
        }
    }

    &.top .tooltip-content {
        @include mobileUp {
            left: unset;
            right: unset;
            top: unset;
            bottom: 100%;
            margin: 0 0 16px 0;
            transform: translateX(calc(-50% + 10px));

            &::after {
                right: unset;
                bottom: unset;
                left: 50%;
                top: 100%;
                transform: translate(-50%, -40%) rotate(-90deg);
            }
        }
    }

    .tooltip-trigger {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        vertical-align: middle;
        outline: none !important;

        width: 20px;
        height: 20px;

        border-radius: 50%;
        background-color: rgba($blackBlue, 0.24);
        box-sizing: border-box;

        transition: all 0.25s;
        cursor: pointer;

        &:hover {
            background-color: rgba($blackBlue, 0.32);
        }

        .question-icon-holder {
            display: flex;

            path {
                transition: 250ms;
            }
        }
    }

    .tooltip-content {
        display: none;
        position: absolute;
        z-index: 10;

        justify-content: center;
        align-items: center;
        width: max-content;

        min-height: 40px;
        max-width: 300px;
        margin-top: 8px;
        padding: 8px 14px;

        background: $blackBlue;
        box-sizing: border-box;
        border-radius: 8px;

        color: $white;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;

        @include mobile {
            position: absolute;
            left: $container-padding-mobile;
            right: $container-padding-mobile;

            width: auto;
            max-width: calc(100vw - ($container-padding-mobile * 2));

            border: none;
            text-align: center;
        }

        @include mobileUp {
            left: 100%;
            top: 50%;
            margin: 0 0 0 16px;
            transform: translateY(-50%);

            &::after {
                content: url("~@/assets/img/icons/tootlip-left-arrow.svg");
                position: absolute;
                right: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        ul {
            width: 100%;
            margin: 0;
            padding: 12px;
            list-style: none;
            font-size: 15px;

            @include mobileUp {
                padding: 8px 12px;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                line-height: 24px;
                margin: 0 0 15px 0;

                span {
                    margin: 0 0 0 5px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: $primaryDisabled;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                @include mobile {
                    margin-bottom: 2px;
                }

                @include mobileUp {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 28px;
                    margin: 0;

                    span {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
</style>
