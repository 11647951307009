import { CAR_PARAMS } from "@/utils/_consts";
import { filterIsArray } from "@/utils/filters";
import { mapGetters, mapState } from "vuex";

export default {
    props: {
        options: Array,
        values: Object,
        lowercase: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        listOptions() {
            return this.options ?? Object.keys(this.values ?? {});
        },
        params() {
            return this.listOptions.reduce((params, param) => {
                if (this.isShowParam(param)) {
                    let value = this.values[param]?.title || this.values[param];
                    let valueAlias = this.values[param]?.alias || this.values[param];
                    if (filterIsArray(param) && Array.isArray(value)) {
                        const list = this.getGlossaryCarList(param);
                        if (list) {
                            value = list
                                .filter(({ alias, code }) => value.includes(alias) || (code && value.includes(code)))
                                .map(({ title }) => title)
                                .join(', ');
                        }
                    }
                    else if(value?.min) {
                        if(value.min === value.max) value = value.min;
                        else value = `${value.min}-${value.max}`;
                    }
                    if(value) {
                        const option = this.glossariesOptions[param];
                        params.push({
                            name: option?.shorthand ?? CAR_PARAMS[param] ?? param,
                            value,
                            alias: param,
                            valueAlias,
                            icon: this.getIcon(param),
                        });
                    }
                }
                return params;
            }, []);
        },
        ...mapState('glossary', ['glossariesOptions']),
        ...mapGetters('glossary', ['getGlossaryCarList']),
    },
    methods: {
        isShowParam(param){
            if(this.options) return this.options.includes(param);
            else return this.values && this.values.hasOwnProperty(param)
        },
        getIcon(type){
            try {
                return require(`@/assets/img/icons/params/${type}.svg`);
            }
            catch(e) {}
        }
    }
}
