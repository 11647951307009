<template>
    <div class="car-preview-list" v-if="show">
        <h2 class="preview-title" v-if="title">
            {{ title }}
        </h2>
        <ShimmerLoaderCatalogCars v-if="pending" :length="4" small />
        <client-only>
            <hooper
                v-if="!$media.lg"
                class="car-preview-slider"
                :itemsToShow="itemsToShow"
                pagination="no"
                :wheelControl="false"
                :trimWhiteSpace="true"
            >
                <slide class="car-preview-wrapper" v-for="(car, i) in list" :key="car.id" :index="i">
                    <component
                        :is="carComponent"
                        :car="car"
                        :key="car.id"
                        @click.native="$emit('car', car)"
                    />
                </slide>
                <navigation slot="hooper-addons"></navigation>
            </hooper>
            <template v-else>
                <mobile-slider v-if="isMobileSlider" :list="shortList">
                    <template #default="{item: car}">
                        <component
                            :is="carComponent"
                            :car="car"
                            :key="car.id"
                            @click.native="$emit('car', car)"
                        />
                    </template>
                </mobile-slider>
                <div v-else class="car-preview-grid">
                    <component
                        :is="carComponent"
                        v-for="car in shortList"
                        :car="car"
                        :key="car.id"
                        @click.native="$emit('car', car)"
                    />
                </div>
            </template>
        </client-only>
    </div>
</template>

<script>
import { Hooper, Navigation, Slide } from 'hooper';
import CarPreview from "@/components/cars/_car/carPreview";
import carList from "@/components/cars/_car/carList";
import MobileSlider from "@/components/mainPage/modules/MobileSlider";
import ShimmerLoaderCatalogCars from '@/components/cars/_car/ShimmerLoaderCatalogCars';

export default {
    name: "CarPreviewList",
    components: {
        ShimmerLoaderCatalogCars,
        MobileSlider,
        CarPreview, Hooper, Slide, Navigation,
    },
    mixins: [carList],
    props: {
        carsList: {
            default: false
        },
        exclude: {
            default: false
        },
        isMobileSlider: {
            default: false,
        },
        title: {
            type: String,
        },
        itemsToShow: {
            type: Number,
            default: 4,
        },
        carComponent: {
            type: Object,
            default: () => CarPreview,
        },
        pending: Boolean,
    },
    computed: {
        shortList() {
            return this.list.slice(0, this.itemsToShow);
        },
        show() {
            if (this.exclude && !this.cars.paramsReq?.filter?.exclude) {
                return false;
            } else {
                return true;
            }
        },
    },
}
</script>

<style lang="scss">
.car-preview-list {

    .preview-title {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;

        @include mobileUp {
            margin-bottom: 24px;
            font-size: 32px;
            line-height: 40px;
        }
    }

    .mobile-slider {
        @include mobile {
            margin-bottom: 24px;
        }
    }

    .car-preview-slider {
        height: auto;
        width: calc(100% + 24px);
        margin: 0 -12px;

        @include tablet {
            display: none !important;
        }

        .car-preview-wrapper {
            padding: 0 12px;
        }

        .hooper-navigation {
            .hooper-prev, .hooper-next {
                width: 56px;
                height: 56px;

                box-shadow: 0 2px 24px -8px rgba(5, 47, 95, 0.32);
                transition: 250ms box-shadow;

                @include PCUp {
                    box-shadow: none;
                }

                &:hover {
                    box-shadow: 0 0.4px 1px rgba(11, 33, 65, 0.04), 0 12px 48px -8px rgba(11, 33, 65, 0.12);

                    svg {
                        opacity: 1;
                    }
                }

                svg {
                    transition: 250ms opacity;
                    opacity: 0.48;
                }
            }

            .hooper-prev {
                @include PCUp {
                    left: -68px;
                }
            }

            .hooper-next {
                @include PCUp {
                    right: -68px;
                }
            }
        }
    }

    .car-preview-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;

        @include mobileUp {
            grid-template-columns: 1fr 1fr;
        }
    }

    .car-card-vertical {
        @include mobile {
            margin: 0 $negative-container-padding-mobile;

            .car-photo {
                margin: 0;
            }
        }
    }
}
</style>
