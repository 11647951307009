// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/car/car-no-image.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".car-preview-card-link{display:flex;width:100%;min-width:100%;transition:.25s}.car-preview-card-link:hover{text-decoration:none}.car-preview-card-link:hover .car-preview-card{box-shadow:0 .4px 1px rgba(11,33,65,.02),0 8px 40px -4px rgba(11,33,65,.04)}.car-preview-card-link:hover .car-preview-card .title-car{color:#307efd}.car-preview-card{display:flex;flex-direction:column;width:100%;min-width:100%;background-color:#fff;border-radius:8px;overflow:hidden;transition:.25s}.car-preview-card .car-photo{margin:0}.car-preview-card:hover .vehicle-card-main-photo .hooper .hooper-navigation{opacity:1}.car-preview-card .main-photo-slider,.car-preview-card .vehicle-card-main-photo{height:174px;border-radius:0}.car-preview-card .main-photo{display:block;width:100%;height:174px;-o-object-fit:cover;object-fit:cover;background:#f2f4f8;position:relative}.car-preview-card .main-photo:after{content:\"\";position:absolute;top:0;left:0;display:block;width:100%;height:100%;background:#f2f4f8 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover}.car-preview-card .info-body,.car-preview-card .info-header{padding:10px 16px 12px}.car-preview-card .info-header{border-bottom:1px solid rgba(23,35,53,.04)}.car-preview-card .info-body{padding:12px 16px 16px}.car-preview-card .info-body .daily-price{display:flex;align-items:flex-end;color:#172335}.car-preview-card .info-body .daily-price .price{font-weight:700;font-size:20px;line-height:28px}.car-preview-card .info-body .daily-price .postfix{margin-left:8px;padding-bottom:1px;font-weight:400;font-size:14px;line-height:22px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
