<template>
    <modal-filter :title="title" :id="glossary" class="select" v-slot="{close}">
        <div class="item" v-for="{id, alias, title} in list" :key="id"
             :class="{active: value === alias}" @click="select(alias, close)">
            {{title}}
        </div>
    </modal-filter>
</template>

<script>
    import ModalFilter from "@/components/search/filters/_modal";
    import filterList from "@/mixins/filters/filerList";

    export default {
        name: "filterList",
        components: {ModalFilter},
        mixins: [filterList],
        props: {
            title: String,
        },
        methods: {
            select(alias, close){
                this.value = alias;
                close();
            }
        }
    }
</script>
