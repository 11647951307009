<template>
    <router-link :to="link" class="news-post-preview" v-lazy-background :lazy-background="imageBg">
        <div class="news-post-content">
            <div class="news-post-category">
                <PostCategory
                    :category="category"
                    :post-type="postType"
                />
            </div>
            <div class="news-post-body">
                <div class="news-post-title">
                    {{title}}
                </div>
                <div class="news-post-date" v-if="date">
                    <img v-lazy-load src="@/assets/img/icons/news-post-date.svg" alt="date">
                    <div class="news-post-date-text">
                        {{dateString}}
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>

import PostCategory from "@/components/blog/badges/PostCategory";

export default {
    name: "NewsPostPreview",
    components: {PostCategory},
    inheritAttrs: false,
    props: {
        category: Object,
        active_from: String,
        created_at: String,
        updated_at: String,
        images: [Object, String],
        description: String,
        title: String,
        alias: String,
        postType: String,
    },
    computed: {
        imageBg() {
            try {
                return this.images?.md || this.images || require('@/assets/img/_dev/selection-item.jpg');
            } catch (e) {

            }
        },
        date() {
            return this.active_from || this.created_at || this.updated_at
        },
        dateString() {
            return this.$moment(this.date).format('DD MMMM, YYYY')
        },
        link() {
            if (!this.alias) {
                return {};
            }
            return {
                name: `${this.postType}-alias`,
                params: {
                    alias: this.alias,
                },
            };
        },
    },
}
</script>

<style lang="scss">
.news-post-preview {
    display: block;
    position: relative;

    background-color: $darkLight;
    background-size: cover;
    background-position: center;

    border-radius: 8px;
    overflow: hidden;

    cursor: pointer;
    transition: 250ms;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        transition: all 250ms ease-in;
    }

    &::after {
        background-image: linear-gradient(180.22deg, rgba(17, 25, 38, 0) 38.42%, rgba(17, 25, 38, 0.48) 67.23%, rgba(17, 25, 38, 0.8) 99.81%);
    }

    &:hover {
        box-shadow: 0 28px 48px -28px rgba(13, 41, 82, 0.4);
        text-decoration: none;

        &::after {
            background-image: linear-gradient(180.22deg, rgba(23, 43, 73, 0) 38.42%, rgba(23, 43, 73, 0.48) 67.23%, rgba(23, 43, 73, 0.8) 99.81%)
        }
    }


    .news-post-content {
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 16px;
        min-height: 320px;


        @include mobileUp {
            padding: 20px;
            min-height: 344px;
        }

        .news-post-category {
        }

        .news-post-body {
            margin-top: 16px;
            color: $white;

            .news-post-title {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.01em;

                @include mobileUp {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            .news-post-date {
                display: flex;
                align-items: center;

                margin-top: 12px;

                @include mobileUp {
                    margin-top: 16px;
                }

                .news-post-date-text {
                    margin-left: 6px;

                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
}
</style>
