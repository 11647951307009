import {CarModel, CarModelCatalog} from "../types";
import {joinStrings} from "../joinStrings.js";
import {declensionEngineVolume} from "~/utils/word/index";

export function carImgSeo(car: CarModelCatalog | CarModel, city: string, index: number = 0, isDetailed = false) {
    if (!car) return {};
    let {
        brand: {alias: brand},
        brand_model: {alias: brandModel},
        year,
        min_price_per_day,
        price_day,
    } = car;

    const metro = car.metro?.title || null;
    const {id: isCasco, title: casco} = (car.casco_type ?? {});
    const {id: isOsago, title: osago} = (car.osago_type ?? {});
    const {title: carClass = null} = (car.options?.car_class ?? {});
    const {title: drive = null} = (car.options?.drive ?? {});
    const {title: engineType = null} = (car.options?.engine_type ?? {});
    const engineVolume = car.options?.engine_volume;

    const minPrice = min_price_per_day ?? price_day;
    const position = index + 1;

    const _if = (condition, ...strings) => condition ? strings.join(' ') : null;
    const _everyBase = (...strings) => strings.every(v => v) ? strings.join(' ') : null;
    const _every = (...strings) => _everyBase(...strings)?.toLowerCase();

    return {
        title: joinStrings(
            _if(isDetailed, 'Аренда'),
            brand,
            brandModel,
            year,
            'года',
            _if(index, '- вид', position),
        ),
        alt: joinStrings(
            isDetailed ? 'Аренда' : 'Прокат авто',
            brand,
            brandModel,
            _every(carClass, 'класса'),
            _every(year, 'года'),
            _everyBase('в городе', city),
            metro,
            _every('от', minPrice, 'руб./сутки,'),
            _every(drive, 'привод,'),
            _every('двигатель:', engineType, ','),
            _every('объем', engineVolume, `${declensionEngineVolume(engineVolume)},`),
            _if(isCasco, `каско (${casco}),`),
            _if(isOsago, `ОСАГО (${osago}),`),
            'без водителя, недорого',
            _if(index, ', вид', position),
            '- RentRide',
        ).replace(/ ,/g, ','),
    }
}
