import { getCarInfo } from "@/utils/meta";
import { declensionYears } from '~/utils/word';
import moment from 'moment';

export function schemaOrg(type, data = {}) {
    return {
        '@context': 'http://schema.org',
        ...data,
        '@type': type,
    }
}

export function schemaOrgName(name, type = 'Thing') {
    return schemaOrg(type, { name });
}

export function schemaOrgProductCar(car, reviews, url, other = {}) {
    const { brand, brand_model, images, rating } = car;
    const image = images && images[0];

    return schemaOrg('Product', {
        identifier: car?.id,
        sku: car?.id,
        url,
        name: car && getCarInfo(car)?.title,
        description: getCarDescription(car),
        image: image?.lg ?? image,
        brand: schemaOrgName(brand?.title, 'Brand'),
        review: getCarReviews(reviews),
        category: brand_model?.title,
        offers: getCarOffers(car?.min_price_per_day, car?.prices, url),
        ...getRating(car),
        ...other,
    });
}

export function schemaOrgCar(car, url, other = {}) {
    const { options, year, brand, brand_model, images, rating } = car;
    const { cars_body, engine_type, seats, drive, transmission, doors } = options || {};
    const image = images && images[0];

    return schemaOrg('Car', {
        additionalType: 'Product',
        identifier: car?.id,
        sku: car?.id,
        url,
        name: car && getCarInfo(car)?.title,
        description: getCarDescription(car),
        image: image?.lg ?? image,
        bodyType: cars_body?.title,
        fuelType: engine_type?.title,
        numberOfDoors: doors ?? 4,
        modelDate: year?.toString(),
        seatingCapacity: seats ?? 5,
        vehicleConfiguration: `${drive?.title} привод, ${engine_type?.title} ${transmission?.shorthand}, ${cars_body?.title}`,
        vehicleEngine: schemaOrg('EngineSpecification', {
            engineType: engine_type?.title,
            fuelType: engine_type?.title,
        }),
        vehicleTransmission: transmission?.title,
        brand: brand?.title,
        category: brand_model?.title,
        offers: getCarOffers(car?.min_price_per_day, car?.prices, url),
        ...getRating(car),
        ...other,
    });
}

function getCarReviews(reviews = []) {
    return reviews.map(({ user, created_at, contents, rating }) => schemaOrg('Review', {
        author: schemaOrgName(`${user.first_name} ${user.last_name}`, 'Person'),
        datePublished: created_at,
        reviewBody: contents,
        reviewRating: schemaOrg('Rating', {
            bestRating: 5,
            ratingValue: rating,
        }),
    }));
}

function getCarDescription(car) {
    let description = car?.description ?? '';
    if (!description && car) {
        const {
            city,
            min_price_per_day,
            mileage_limit,
            deposit,
            min_experience,
            min_age,
            osago_type,
            casco_type,
        } = car;
        const { alias: osago, title: osagoName } = osago_type || {};
        const { alias: casco, title: cascoName } = casco_type || {};

        const { brand, brandModel, year } = getCarInfo(car);
        description = `Аренда ${brand} ${brandModel}, ${year} года от ${min_price_per_day} ₽/сутки в  г. ${city?.title}.\n`;
        description += `Условия автопроката:\n`;
        if (mileage_limit) description += `✓ Включенный пробег - ${mileage_limit} км / сутки\n`;
        if (deposit) description += `✓ Депозит - ${deposit} ₽\n`;
        if (min_experience) description += `✓ Минимальный стаж - ${declensionYears(min_experience)}\n`;
        if (min_age) description += `✓ Минимальный возраст - ${declensionYears(min_age)}\n`;
        if (osago !== 'none' || casco !== 'none') {
            description += `✓ Страховка\n`;
            if (osago !== 'none') {
                description += `✓ ОСАГО - ${osagoName}`;
            }
            if (casco !== 'none') {
                description += `✓ КАСКО - ${cascoName}`;
            }
        }
    }

    return description;
}

function getCarOffers(minPrice, prices, url) {
    return schemaOrg('Offer', {
        price: minPrice,
        priceCurrency: 'RUB',
        availability: 'https://schema.org/InStock',
        url,
        offeredBy: schemaOrg('AutoRental', schemaCompany),
        priceSpecification: prices?.map(price => getUnitPriceSpecification(price)),
        priceValidUntil: moment().add(1, 'y').format('YYYY-MM-DD'),
    });
}

function getRating({ rating, reviews_with_text = 0, reviews_without_text = 0 }) {
    const count = reviews_with_text + reviews_without_text;
    if (!count) return {};
    return {
        aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: rating?.value,
            reviewCount: count,
            bestRating: 5,
        },
    }
}

function getUnitPriceSpecification({ price }) {
    return {
        '@type': 'UnitPriceSpecification',
        unitCode: 'DAY',
        unitText: 'День',
        price: price,
        priceCurrency: "RUB",
        referenceQuantity: {
            '@type': 'QuantitativeValue',
            value: 1,
            unitCode: 'DAY',
        },
    }
}


export const schemaCompany = {
    '@id': 'https://rentride.ru',
    currenciesAccepted: 'RUB',
    openingHours: '9:00 - 21:00',
    address: {
        '@type': 'PostalAddress',
        addressCountry: 'Ru',
        addressLocality: 'Москва',
        streetAddress: 'Нагорный проезд, д. 5',
        postalCode: '117105',
    },
    email: 'info@rentride.ru',
    telephone: '+7 499 450-59-89',
    name: 'RentRide',
    description: 'Машины для путешествия. Выбери автомобиль для поездки по России или за рубеж',
    disambiguatingDescription: 'Стоимость аренды на 25% ниже рынка. Мы объединили предложения частных автовладельцев по всей России. Широкий выбор машин от Lada до Tesla',
    legalName: 'ООО «РентРайд»',
    logo: 'https://rentride.ru/logo.svg',
    image: 'https://rentride.ru/logo.svg',
    url: 'https://rentride.ru',
    priceRange: 'от 1 000 руб до 39 000 руб',
    geo: {
        '@type': 'GeoCoordinates',
        latitude: '55.706716913133015',
        longitude: '37.596366015594135',
    },
}
