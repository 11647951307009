<template>
    <car-link class="car-preview-card-link" :car="car">
        <div class="car-preview-card">
            <VehicleCardMainPhoto
                ref="photo"
                :car="car"
                :slider-settings="{mouseDrag: false}"
            />
            <div class="info-header">
                <TitleCar :car="car" class="sm" />
                <CarRatingInfo v-bind="car.rating"/>
            </div>
            <div class="info-body">
                <div class="daily-price">
                    <div class="price">
                        от {{ $filters.price(priceWord) }}
                    </div>
                    <div class="postfix">
                        / сутки
                    </div>
                </div>
            </div>
        </div>
    </car-link>
</template>

<script>

import TitleCar from "@/components/cars/_car/titleCar";
import CarLink from "@/components/cars/_car/carLink";
import { declensionReviews } from "@/utils/word";
import VehicleCardMainPhoto from "@/components/vehicles/VehicleCardMainPhoto";
import prices from "@/components/cars/_car/prices";
import CarRatingInfo from "@/components/cars/_car/CarRatingInfo";

export default {
    name: "carPreview",
    components: {CarRatingInfo, VehicleCardMainPhoto, CarLink, TitleCar },
    mixins: [prices],
    props: {
        car: Object,
    },
    computed: {
        ratingValue() {
            return this.car.rating?.value || 0;
        },
        ratingValueString() {
            return (this.ratingValue).toFixed(1);
        },
        ratingCount() {
            return Math.round(this.car.rating?.reviews || 0);
        },
        ratingCountString() {
            return declensionReviews(this.ratingCount);
        },
    },
    async mounted() {
        await this.$nextTick();
        this.$refs?.photo?.$refs?.slider?.update();
    },
}
</script>

<style lang="scss">
.car-preview-card-link {
    display: flex;
    width: 100%;
    min-width: 100%;

    transition: 250ms;

    &:hover {
        text-decoration: none;

        .car-preview-card {
            box-shadow: 0 0.4px 1px rgba(11, 33, 65, 0.02), 0 8px 40px -4px rgba(11, 33, 65, 0.04);

            .title-car {
                color: #307EFD;
            }
        }
    }
}
.car-preview-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;

    background-color: $white;
    border-radius: 8px;

    overflow: hidden;
    transition: 250ms;

    .car-photo {
        margin: 0;
    }

    &:hover {
        .vehicle-card-main-photo .hooper .hooper-navigation {
            opacity: 1;
        }
    }

    .vehicle-card-main-photo,
    .main-photo-slider {
        height: 174px;
        border-radius: 0;
    }

    .main-photo {
        display: block;
        width: 100%;
        height: 174px;
        object-fit: cover;
        background: #F2F4F8;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: #F2F4F8 url("~@/assets/img/car/car-no-image.svg") no-repeat center;
            background-size: cover;
        }
    }

    .info-header, .info-body {
        padding: 10px 16px 12px;
    }

    .info-header {
        border-bottom: 1px solid rgba(23, 35, 53, 0.04);
    }

    .info-body {
        padding: 12px 16px 16px;

        .daily-price {
            display: flex;
            align-items: flex-end;
            color: $blackBlue;

            .price {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
            }

            .postfix {
                margin-left: 8px;
                padding-bottom: 1px;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
</style>
