<template>
    <div class="car-info-price" v-if="price">
        <span class="price">
            <template v-if="group">от </template>
            {{  $filters.price(priceWord) }}
        </span>
        <span class="period" v-if="!isPeriod">&nbsp;/&nbsp;сутки</span>
        <tooltip class="primary bottom" v-if="showPrices && prices.length">
            <img v-lazy-load src="@/assets/img/icons/arrow-red.svg" alt="" slot="default">
            <template slot="content">
                <ul>
                    <li v-for="({price, min_time}, i) in prices" :key="i">
                        <b>{{ price }} ₽</b>
                        <span>от {{ min_time }} дней</span>
                    </li>
                </ul>
            </template>
        </tooltip>
    </div>
</template>
<script>
import Tooltip from "@/components/_elements/tooltip"
import { mapGetters } from "vuex";

export default {
    name: 'prices-car',
    props: {
        car: Object,
        period: Object,
        showPrices: {
            type: Boolean,
            default: true,
        },
    },
    components: { Tooltip },
        data: () => ({
            selected: false
        }),
        computed: {
            price(){
                return this.car?.max_price_per_day;
            },
            group(){
                return this.car?.items;
            },
            prices() {
                if (this.car?.prices) {
                    return this.car.prices?.filter(({ type }) => type === 2) ?? [];
                } else {
                    return [];
                }
            },
            isPeriod(){
                return this.period?.period_start && this.period?.period_end;
            },
            priceWord(){
                if(this.isPeriod) {
                    if(this.group) {
                        return this.minPriceGroup;
                    } else {
                        return this.getPrice(this.car);
                    }
                } else {
                    return this.car?.price_day || this.minPrice || this.price?.min || this.price;
                }
            },
            minPrice() {
                const prices = this.prices.map(({ price }) => price).filter(price => price);
                if (prices.length) {
                    return Math.min(...prices);
                }
            },
            minPriceGroup() {
                if (this.group) {
                    const prices = this.group.map(car => this.getPrice(car)).filter(price => price);
                    if (prices.length) {
                        return Math.min(...prices);
                    }
                }
            },
            ...mapGetters('rent', ['getPricePeriod']),
        },
        methods: {
            getPrice(car){
                return this.getPriceObj(car)?.rent;
            },
            getPriceObj(car = this.car){
                return this.getPricePeriod(this.period, true, car);
            },
            getType(type) {
                if (type === 1) return 'в час';
                else if (type === 2) return 'в сутки';
                else console.warn('Неизвестный период аренды:', type);
            }
        },
        watch: {
            list: {
                immediate: true,
                handler(list) {
                    if (list) this.selected = list[0];
                }
            }
        },
    }
</script>

<style lang="scss">
    .car-info-price {
        white-space: nowrap;
        font-size: 24px;
        line-height: 32px;

        .period {
            color: $primaryDisabledLight;
        }

        .period, .price {
            font-weight: 800;
        }


        @include mobileUp {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 26px;
        }

        .tooltip-wrapper.bottom {
            display: inline-block;

            @include mobile {
                display: inline;
            }

            .tooltip-trigger {
                width: 26px;
                height: 26px;
                color: rgba(23, 35, 53, 0.4);
                border: 1px solid $greyLine;
                background: transparent;
            }

            .tooltip-content {
                width: 260px;
                max-width: calc(80% - 32px);
                padding: 0 3px;
                background: #FFFFFF;
                font-size: 12px;
                margin-top: 36px;
                color: $blackBlue;

                @include mobile {
                    width: 100%;
                    max-width: 100%;
                    background: $grayBackground;
                    margin-top: 8px;
                }

                @include mobileUp {
                    width: 280px;
                    min-width: 280px;
                }
            }

            &.open {
                .tooltip-trigger {
                    background: $primary;
                    border-color: $primary;

                    img {
                        filter: brightness(10);
                    }
                }

                .tooltip-content {
                    display: block;
                }
            }
        }
    }
</style>
