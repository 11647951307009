<template>
    <BonusPointsActivateModal
        class="NewUsersPromotionRegistrationModal"
        :id="$modals.NEW_USERS_REGISTRATION_PROMOTION"
        expiring-date="Время действия акции ограничено <br><b>1 балл = 1 рубль</b>"
        button-text="Зарегистрироваться"
        active
        @activate="onActivate"
        @click-close="onClose"
    >
        <div class="NewUsersPromotionRegistrationModal__body">
            <div class="NewUsersPromotionRegistrationModal__title-block">
                <div class="NewUsersPromotionRegistrationModal__points-title-line">
                    <div class="NewUsersPromotionRegistrationModal__points-badge">
                        500
                    </div>
                    <div class="NewUsersPromotionRegistrationModal__points-label">
                        баллов
                    </div>
                </div>
                <div class="NewUsersPromotionRegistrationModal__title-description">
                    для новых <br>
                    пользователей
                </div>
            </div>
            <div class="NewUsersPromotionRegistrationModal__info">
                <p>
                    Сэкономьте на поездках <br>
                    и познакомьтесь с крутым <br>
                    продуктом!
                </p>
                <ul>
                    <li>
                        Парк из 700+ автомобилей <br>
                        на любой вкус
                    </li>
                    <li>
                        Возможность путешествовать <br>
                        по всей России
                    </li>
                    <li>
                        Дешевле каршеринга
                    </li>
                </ul>
            </div>
            <img v-lazy-load
                class="NewUsersPromotionRegistrationModal__illustration"
                src="@/assets/img/icons/new-users-registration-promotion-illustration.png"
                alt=""
            >
        </div>
    </BonusPointsActivateModal>
</template>

<script>
import BonusPointsActivateModal from "@/components/_elements/BonusPointsActivateModal";
import {goToAuth} from "@/utils/auth";
import {mapMutations, mapState} from "vuex";
import profilePaymentBonusPoints from "@/mixins/profile/profilePaymentBonusPoints";

export default {
    name: "NewUsersPromotionRegistrationModal",
    components: {BonusPointsActivateModal},
    mixins: [profilePaymentBonusPoints],
    created() {
        if (this.$auth.loggedIn && this.activateNewUserPromo && this.authCodeResult?.settings?.new_registration) {
            this.openModal({
                name: this.$modals.PAYMENT_BONUS_CONGRATULATIONS,
                data: {points: this.profilePaymentBonusPoints},
            });
        }
        this.setActivateNewUserPromo(false);
    },
    computed: {
      ...mapState('promotions', ['activateNewUserPromo']),
      ...mapState('profile', ['authCodeResult']),
    },
    methods: {
        onActivate() {
            this.$signals.newbiesBonusPromotionClickRegistration();
            this.setActivateNewUserPromo(true);
            goToAuth(this)
        },
        onClose() {
            this.$signals.newbiesBonusPromotionClickCloseModal();
        },
        ...mapMutations('modal', ['openModal']),
        ...mapMutations('promotions', ['setActivateNewUserPromo']),
    },
}
</script>

<style lang="scss">
.NewUsersPromotionRegistrationModal {

    &__body {
        position: relative;
        z-index: 1;
    }

    &__title-block {
        margin-bottom: 16px;

        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: $white;
    }

    &__points {

        &-title-line {
            display: flex;
            align-items: flex-end;
            margin-bottom: 3px;
        }

        &-badge {
            position: relative;
            z-index: 1;

            margin-right: 15px;
            margin-bottom: 1px;

            font-size: 40px;
            line-height: 42px;

            &::before {
                content: '';
                z-index: -1;

                position: absolute;
                top: -2px;
                right: -9px;
                left: -10px;
                bottom: -2px;

                background: linear-gradient(170.03deg, #50BADD -12.1%, #5394FD 92.52%);
                border-radius: 8px;
                transform: rotate(-2deg);
            }
        }
    }

    &__info {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: $white;

        p, ul {
            margin: 0;
        }

        ul {
            padding-left: 16px;

            li {
                margin-top: 16px;
            }
        }
    }

    &__illustration {
        position: absolute;
        top: -12px;
        right: -28px;
        z-index: -1;
    }
}
</style>
