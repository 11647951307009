<template>
    <div class="short-rating" :class="[{empty}, styleThemeClass]">
        <div v-if="!empty" class="rating-value">
            {{ ratingString }}
        </div>
        <span
            class="star-holder"
            v-html="RatingStarIcon"
        />
    </div>
</template>

<script>
import RatingStarIcon from '@/assets/img/icons/short-rating-star.svg?raw';
import styleTheme from "@/mixins/style/styleTheme";
import {numberToFixedShort} from "@/utils/number/numberToFixedShort";

export default {
    name: "ShortRating",
    mixins: [styleTheme],
    inheritAttrs: false,
    props: {
        rating: {
            type: [Number, String],
            default: 0,
        },
    },
    data: () => ({
        RatingStarIcon,
    }),
    computed: {
        empty() {
            return !this.rating;
        },
        ratingString() {
            if (this.empty) {
                return '0';
            }
            return numberToFixedShort(this.rating);
        },
    },
}
</script>

<style lang="scss">
.short-rating {
    display: inline-flex;
    align-items: center;

    &.style-theme-audi {
        .rating-value {
            margin-right: 6px;

            font-size: 16px;
            line-height: 24px;
            color: $audi_accent;
        }

        .star-holder {
            svg {
                width: 16px;
                height: 16px;
            }

            path {
                fill: $audi_accent;
            }
        }
    }

    .rating-value {
        margin-right: 4px;

        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #FF4F17;
    }

    .star-holder {
        display: flex;
    }

    &.empty {
        .rating-value {
            color: rgba($blackBlue, 0.24);
        }

        .star-holder {
            path {
                fill: rgba($blackBlue, 0.24);
            }
        }
    }
}
</style>
