<template>
    <div class="new-cars-module">
        <module-title title="Новинки в каталоге" tag="New" />
        <car-preview-list
            :pending="pending"
            :is-mobile-slider="true"
            :cars-list="list || []"
            @car="$signals.newCarsGoCar"
        />
    </div>
</template>

<script>
import ModuleTitle from "@/components/mainPage/ModuleTitle";
import CarPreviewList from "@/components/cars/_car/CarPreviewList";

export default {
    name: "NewCarsModule",
    components: {CarPreviewList, ModuleTitle},
    props: {
        list: Array,
        pending: Boolean,
    }
}
</script>

<style lang="scss">
.new-cars-module {

}
</style>
