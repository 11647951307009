// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/icons/car-show-more-arrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination{display:flex;flex-direction:column;align-items:center}.pagination .show-more-button{margin-bottom:32px;padding-right:36px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:right 50%;background-size:24px;background-repeat:no-repeat;font-weight:400;font-size:28px;line-height:32px;letter-spacing:-.02em;color:#172335;cursor:pointer}@media(max-width:768px){.pagination .show-more-button{margin-bottom:20px;font-size:16px;line-height:24px;letter-spacing:-.01em}}.pagination .show-more-button.disabled{cursor:default;opacity:.5}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
