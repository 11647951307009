<template>
    <div class="datepicker-month">
        <DatepickerWeekday
            :preview-mode="previewMode"
        />
        <div class="datepicker-days">
            <div
                class="datepicker-day-wrapper datepicker-day empty"
                v-for="n in emptyDays"
                :key="n"
            />
            <DatepickerDay
                v-for="({date, previous}, day) in daysInMonth"
                :key="date"
                :day="day + 1"
                :date="date"
                :previous="previous"
                :selected="selected"
                :today="isToday(date)"
                :disabled="!!validateDay && !validateDay(date)"
                :is-single-date-picker="isSingleDatePicker"
                :preview-mode="previewMode"
                :period-data="getDayPeriodData(date)"
                :color-number-map="colorNumberMap"
                @select="$emit('select', $event)"
            />
        </div>
    </div>
</template>

<script>
import moment from "moment";
import _uniq from 'lodash/uniq';
import DatepickerWeekday from "@/components/_elements/datapicker/DatepickerWeekday";
import DatepickerDay from "@/components/_elements/datapicker/DatepickerDay";
import {mapObject} from "@/utils/mapObject";

const DAY = 86400000;
const momentToday = moment();

export default {
    name: 'datepicker-month',
    components: {DatepickerDay, DatepickerWeekday},
    props: {
        previous: Number,
        date: Number,
        selected: [Object, Number],
        validateDay: Function,
        isSingleDatePicker: Boolean,
        previewMode: Boolean,
        coloredPeriods: Array,
    },
        computed: {
            emptyDays(){
                return this.$moment(this.date).weekday();
            },
            daysInMonth(){
                const all = this.$moment(this.date).daysInMonth();
                let days = [];
                for (let day = 0; day < all; day++) {
                    const date = this.date + day * DAY;
                    days.push({
                        date,
                        previous: this.previous > date,
                    });
                }
                return days;
            },
            colorNumberMap() {
                if (this.coloredPeriods) {
                    // Example: {'#FFFFFF': 0, #000000: 1};
                    return mapObject(_uniq(this.coloredPeriods.map(({color}) => color)), ([k, v]) => [v, k]);
                }
            },
        },
        methods: {
            isToday(date) {
                return momentToday.isSame(date, 'day');
            },
            getDayPeriodData(date) {
                const $date = this.$moment(date);
                if (this.coloredPeriods) {
                    const coloredPeriod = this.coloredPeriods.find(({start, end}) => {
                        return $date.isSameOrAfter(start, 'day') && $date.isSameOrBefore(end, 'day');
                    });

                    if (coloredPeriod) {
                        const {start, end, color} = coloredPeriod;
                        return {
                            isStart: $date.isSame(start, 'day'),
                            isEnd: $date.isSame(end, 'day'),
                            color,
                        };
                    }
                }
            },
        },
    }
</script>

<style lang="scss">
    .datepicker-month {

        .datepicker-days {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 4px 3px;
            justify-items: center;

            @include mobile {
                grid-gap: 4px 8px;
            }

            @media (max-width: 480px) {
                grid-gap: 4px;
            }
        }
    }
</style>
