<template>
    <div
        class="datepicker-day-wrapper"
        :class="[
            colorNumberClass,
            {
                active, interval, start, end, previous, today, disabled,
                colored: periodData && periodData.color,
                preview: previewMode,
            }]"
        :style="colorStyle"
    >
        <div class="datepicker-day" @click="select">
            {{ day }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'datepicker-day',
    props: {
        previous: Boolean,
        day: Number,
        date: Number,
        selected: [Object, Number],
        disabled: Boolean,
        today: Boolean,
        isSingleDatePicker: Boolean,
        previewMode: Boolean,
        periodData: Object,
        colorNumberMap: Object,
    },
    computed: {
        active() {
            if (this.previewMode) {
                return false;
            }
            return this.start || this.end;
        },
        start() {
            if (this.periodData?.isStart) {
                return true;
            }
            const date = this.isSingleDatePicker ? this.selected : this.selected.start;
            if (date) {
                return this.isSameDay(date);
            }
        },
        end() {
            if (this.periodData?.isEnd) {
                return true;
            }
            const date = this.isSingleDatePicker ? this.selected : this.selected.end;
            if (date) {
                return this.isSameDay(date);
            }
        },
        interval() {
            if (this.isSingleDatePicker) {
                return false;
            }
            if (this.periodData) {
                return true;
            }
            const {end, start} = this.selected;
            if (end && start) {
                return this.$moment(this.date).endOf('day').isAfter(start)
                    && this.$moment(this.date).startOf('day').isBefore(end);
            }
        },
        momentDate() {
            return this.$moment(this.date);
        },
        colorStyle() {
            if (this.periodData) {
                return {
                    background: this.periodData.color,
                }
            }
        },
        colorNumber() {
            if (this.periodData) {
                return this.colorNumberMap[this.periodData.color];
            }
        },
        colorNumberClass() {
            if (this.colorNumber) {
                return `color-${this.colorNumber}`;
            }
        },
    },
    methods: {
        select() {
            if (this.disabled || this.previous || this.previewMode) return;
            this.$emit('select', this.date);
        },
        isSameDay(date) {
            return this.momentDate.isSame(date, 'day');
        },
    },
}
</script>

<style lang="scss">
.datepicker-day-wrapper {
    @mixin dayLeftBorderRadius {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    @mixin dayRightBorderRadius {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    @mixin fillSpaceBeforeDay($width) {
        margin-left: -$width;
        padding-left: $width;
        width: calc(100% + $width);
    }

    @mixin resetFillSpaceBeforeDay() {
        margin-left: 0 !important;
        padding-left: 0 !important;
        width: 100% !important;
    }

    position: relative;

    &:nth-child(7n + 1) {
        @include dayLeftBorderRadius;
        @include resetFillSpaceBeforeDay;
    }

    &:nth-child(7n) {
        @include dayRightBorderRadius;
    }

    &:last-child {
        @include dayRightBorderRadius;
    }

    .datepicker-day {
        display: flex;
        align-items: center;
        justify-content: center;

        width: $dataPickerSizeDay;
        height: $dataPickerSizeDay;

        font-weight: normal;
        font-size: 14px;
        line-height: 22px;

        border-radius: 8px;
        cursor: pointer;
        color: $blackBlue;
    }

    &.preview {

        .datepicker-day {
            @include tabletUp {
                width: $previewModeDataPickerSizeDay;
                height: $previewModeDataPickerSizeDay;
            }

            cursor: default;
        }
    }

    &:not(.disabled, .preview) {
        .datepicker-day {
            &:hover {
                background: $white;
                border: 1px solid $primary;
                color: $blackBlue;
            }
        }
    }

    &.empty {
        background: transparent !important;
        opacity: 0 !important;

        .datepicker-day {
            cursor: default;
        }

        & + .datepicker-day-wrapper {
            @include dayLeftBorderRadius;
        }
    }

    &.interval:not(.colored) {
        background: #EBF2FF;

        &:hover {
            z-index: 5;
        }
    }

    &.interval + &.interval,
    &.interval + &.end {
        @include fillSpaceBeforeDay(4px);

        @include mobile {
            @include fillSpaceBeforeDay(9px);
        }

        @media (max-width: 480px) {
            @include fillSpaceBeforeDay(5px);
        }

        &::after {
            content: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            width: 3px;
            background: #EBF2FF;

            @include mobile {
                width: 8px;
            }

            @media (max-width: 480px) {
                width: 4px;
            }
        }
    }

    &.today:not(.disabled, .preview) {
        &::before {
            content: '';
            position: absolute;
            bottom: 4px;
            right: 20px;
            transform: translateX(50%);

            width: 4px;
            height: 4px;
            background: #307EFD;
            border-radius: 50%;
            flex-shrink: 0;

            pointer-events: none;
        }

        &.active {
            &::before {
                background: $white;
            }
        }
    }

    &.active, &.colored {
        &.start {
            @include dayLeftBorderRadius;
        }

        &.end {
            @include dayRightBorderRadius;
        }
    }

    &.active {
        position: relative;
        z-index: 1;

        .datepicker-day {
            background: $primary;
            color: $white;
        }

        &::before {
            background: $white;
        }
    }

    &.previous:not(.disabled, .preview) {
        opacity: 0.2;
        background: transparent;

        &:hover {
            .datepicker-day {
                cursor: default;
                border: none;
            }
        }
    }

    &.disabled {
        background-image: url("~@/assets/img/icons/calendar-disabled-day-cross.svg");
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;

        .datepicker-day {
            opacity: 0.24;
            cursor: not-allowed;
        }
    }

    // Max 5 colors
    @for $i from 0 through 4 {
        $colorClass: color-#{$i};

        &.#{$colorClass} {

            & + :not(.#{$colorClass}).colored,
            &.end + .colored.start,
            &.end.start + .colored.end {
                @include dayLeftBorderRadius;
                @include resetFillSpaceBeforeDay;
            }
        }
    }
}
</style>
