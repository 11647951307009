<template>
    <component :is="tag" class="title-car" :class="styleThemeClass">
        <span class="brand-model">{{ info.brand }} {{ info.brandModel }}{{ info.year ? divider : '' }}</span><span
        class="year">&nbsp;{{ yearString }}</span>
        <slot></slot>
    </component>
</template>

<script>
import {getCarInfo} from "@/utils/meta";
import styleTheme from "@/mixins/style/styleTheme";

export default {
    name: 'title-car',
    mixins: [styleTheme],
    props: {
        car: Object,
        link: {
            default: true
        },
        divider: {
            default: ','
        },
        tag: {
            default: 'h4'
        }
    },
    computed: {
        info() {
            return getCarInfo(this.car);
        },
        yearString() {
            const year = this.info.year;
            if (this.car.is_fake_group) {
                return `${year - 3} - ${year}`
            } else {
                return year;
            }
        },
    }
}
</script>

<style lang="scss">
.title-car {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 0;

    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: $blackBlue;
    margin-bottom: 12px;

    transition: color 250ms;

    &.style-theme-audi {
        color: $audi_white !important;
    }

    @include mobile {
        order: -1;
    }

    @include mobileUp {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 16px;
    }

    &.sm {
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }

    &.md {
        margin-bottom: 6px;

        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: $blackBlue;

        @include mobileUp {
            margin-bottom: 8px;
            font-size: 40px;
            line-height: 48px;
        }
    }

    &.xs {
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;

        @include mobileUp {
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
        }
    }

    .brand-model {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .year {
        white-space: nowrap;
    }

    .price {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        margin-top: 4px;
    }
}
</style>
