import Slider from "@/components/cars/_car/slider";
import TitleCar from "@/components/cars/_car/titleCar";
import PricesCar from "@/components/cars/_car/prices";
import favouriteBtn from "@/components/cars/_car/favouriteBtn"
import carRating from "@/components/cars/_car/carRating";
import {declensionProposals} from "~/utils/word";

export default {
    name: 'car',
    components: {
        PricesCar,
        Slider,
        TitleCar,
        favouriteBtn,
        carRating,
    },
    props: {
        car: {},
        openWindow: {
            default: false
        },
        period: Object
    },
    computed: {
        collection(){
            return this.car.items;
        },
        proposalsWord(){
            const count = this.collection?.length;
            return declensionProposals(count);
        },
        isOpenWindow() {
            return this.openWindow && !this.collection;
        }
    }
}
