<template>
    <a
        class="OurPartnersItem"
        :href="link"
        rel="nofollow external noopener noreferrer sponsored"
        target="_blank"
    >
        <img v-lazy-load
            class="OurPartnersItem__logo"
            :src="logo"
            :alt="title"
        >
    </a>
</template>

<script>
export default {
    name: "OurPartnersItem",
    inheritAttrs: false,
    props: {
        title: String,
        link: String,
        logo: String,
    },
}
</script>

<style lang="scss">
.OurPartnersItem {
    cursor: pointer;

    &__logo {
        max-width: min(188px, 100%);
        min-width: 48px;
        max-height: 48px;

        @include mobile {
            max-width: min(120px, 100%);
            min-width: 30px;
            max-height: 30px;
        }
    }
}
</style>
