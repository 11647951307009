<template>
    <component
        :is="link ? 'router-link' : 'router-to-cars'"
        :class="{'car-category-preview--marketplace': marketplace}"
        :filters="filters"
        :to="link"
        class="car-category-preview"
    >
        <div class="car-image-holder">
            <webp-img
                :src="img"
                :x2="imgX2"
                class="car-image"
                :class="{isLoaded}"
                :lazy="lazy"
                preload
            />
            <div class="image-placeholder" />
        </div>
        <div class="about-car-text">
            <div class="car-type">{{ name }}</div>
            <div class="car-price">{{ price }}</div>
        </div>
    </component>
</template>

<script>
import RouterToCars from "@/components/router/routerToCars";
import WebpImg from '@/components/_elements/webp-img';

export default {
    name: "CarCategoryPreview",
    components: { WebpImg, RouterToCars },
    props: {
        name: String,
        price: String,
        img: String,
        imgX2: String,
        filters: Object,
        link: [String, Object],
        marketplace: Boolean,
        lazy: Boolean,
    },
    data: () => ({
        isLoaded: false,
    }),
    mounted() {
        this.isLoaded = true;
    },
};
</script>

<style lang="scss">
.car-category-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 8px 0;

    &--marketplace {
        @include mobile {
            background: #FFFFFF;
            border-radius: 12px;
            text-align: center;
            padding: 10px 7px;
            width: 124px;
        }

        .car-image {
            @include mobile {
                max-height: 50px !important;
            }
        }

        .about-car-text {
            @include mobile {
                margin-top: 4px !important;
            }

            .car-type {
                @include mobile {
                    font-weight: 400 !important;
                    font-size: 12px !important;
                    line-height: 18px !important;
                    margin-bottom: 0 !important;
                }
            }

            .car-price {
                @include mobile {
                    font-weight: 500 !important;
                    font-size: 12px !important;
                    line-height: 16px !important;
                }
            }
        }
    }


    &:hover {
        text-decoration: none;

        .about-car-text .car-type {
            color: #307EFD;
        }
    }

    .car-image-holder {
        position: relative;

        .car-image {
            &:not(.isLoaded) {
                position: absolute;
                visibility: hidden;

                & + .image-placeholder {
                    display: block;
                }
            }
        }

        .image-placeholder {
            display: none;

            height: 76px;
            width: 100%;
            position: relative;
            background: rgba($blackLight, 0.08);
            border-radius: 12px;

            @include mobile {
                height: 50px;
            }
        }
    }

    .car-image {
        max-width: 148px;
        max-height: 88px;
        width: 100%;

        object-fit: contain;
    }

    .about-car-text {
        margin-top: 8px;

        @include mobile {
            padding: 0 4px;
        }

        .car-type {
            margin-bottom: 2px;

            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: rgba(23, 35, 53, 0.64);

            transition: color 250ms;

            @include mobileUp {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .car-price {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: $blackBlue;

            @include mobileUp {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.01em;
            }
        }
    }
}
</style>
