export default {
    props: {
        numberOfMonths: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        numberOfMonthsToShow() {
            return !this.$media.md ? this.numberOfMonths : 1;
        },
        monthsListStyles() {
            return {
                gridTemplateColumns: `repeat(${this.numberOfMonthsToShow}, 1fr)`,
            }
        },
    },
}
