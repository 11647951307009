<template>
    <div class="term-filter-block">
        <filter-item-modal-holder class="filter-single-block" filter-name="term">
            <template #header>
                <div class="filter-double-block" v-if="!singe && !$media.md">
                    <filter-item-header class="filter-left-side-block" placeholder="Начало" :value="dateStart">
                        <img v-lazy-load src="@/assets/img/icons/date-time-input-start.svg" alt="start" loading="lazy">
                    </filter-item-header>
                    <filter-item-header class="filter-right-side-block" placeholder="Завершение" :value="dateEnd">
                        <img v-lazy-load src="@/assets/img/icons/date-time-input-end.svg" alt="end" loading="lazy">
                    </filter-item-header>
                </div>
                <filter-item-header v-else :placeholder="placeholder" :value="fullDate" :input="input">
                    <img v-lazy-load src="@/assets/img/icons/date-time-input-start.svg" alt="start">
                </filter-item-header>
            </template>
            <template #body="{close}">
                <filter-term
                    @close="close"
                    v-on="$listeners"
                />
            </template>
        </filter-item-modal-holder>
    </div>
</template>

<script>
import FilterItemHeader from "@/components/mainPage/modules/filter/FilterItemHeader";
import FilterItemModalHolder from "@/components/mainPage/modules/filter/FilterItemModalHolder";
import FilterTerm from "@/components/search/filters/types/filterTerm";
import { mapState } from 'vuex';

const FORMAT_DATE = "dd, DD MMM";
const FORMAT_DATE_MOBILE = "DD MMM";

export default {
    name: "TermFilterBlock",
    components: { FilterTerm, FilterItemModalHolder, FilterItemHeader },
    props: {
        singe: Boolean,
        input: Boolean,
        placeholder: {
            type: String,
            default: 'Когда поедете?',
        },
    },
    computed: {
        dateStart() {
            return this.dateFormat(this.filters.date_from);
        },
        dateEnd() {
            return this.dateFormat(this.filters.date_to);
        },
        fullDate() {
            if (this.dateStart && this.dateEnd) {
                return `${this.dateStart} - ${this.dateEnd}`
            }
        },
        ...mapState("cars", ["filters"]),
    },
    methods: {
        dateFormat(date) {
            if (date) {
                const format = !this.$media.md ? FORMAT_DATE : FORMAT_DATE_MOBILE;
                return this.$moment(date).format(format)
            }
        },
    },
}
</script>

<style lang="scss">
.term-filter-block {

}
</style>
