<template>
    <select class="selections-filter-list" v-model="value">
        <option :value="alias" v-for="{id, alias, title} in list" :key="id">{{title}}</option>
    </select>
</template>

<script>
import filterList from "@/mixins/filters/filerList";

export default {
    name: "filterListMobile",
    mixins: [filterList],
}
</script>

<style lang="scss">
    .selections-filter-list {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        appearance: none;
        opacity: 0;
    }
</style>
