<!-- <template>
    <hooper class="slider-car" ref="carousel"
            @updated="updatedSize" @mousemove.native.passive="mousemove"
            :itemsToShow="pcSlider ? 1 : 1.075" :centerMode="true" :wheelControl="false" :mouseDrag="!pcSlider">
        <slide v-for="(src, i) in list.slice(0, maxPhoto)" :key="i">
            <img v-lazy-load src="@/assets/img/_dev/sample.jpg">
            <div class="other-photo" v-if="list.length > maxPhoto && i == maxPhoto-1">
                <img v-lazy-load src="@/assets/img/icons/camera.svg" alt="">
                <div>Ещё {{list.length - maxPhoto}} фото</div>
            </div>
        </slide>
        <pagination v-if="pcSlider" slot="hooper-addons"></pagination>
    </hooper>
</template>

<script>
    import {Hooper, Slide, Pagination} from 'hooper';

    export default {
        name: "slider",
        props: {
            maxPhoto: {
                default: 5
            }
        },
        components: {
            Hooper,
            Slide,
            Pagination
        },
        data() {
            return {
                list: [1, 2, 3, 4, 5, 6, 7, 8],
                slideWidth: 0,
                pcSlider: !this.$media.md
            }
        },
        watch: {
            pcSlider() {
                this.$refs.carousel?.update();
            }
        },
        computed: {
            sizeCol() {
                return this.slideWidth / Math.min(this.list.length, this.maxPhoto);
            }
        },
        methods: {
            updatedSize({slideWidth}) {
                this.slideWidth = slideWidth;
            },
            mousemove({offsetX}) {
                const slideI = Math.floor(offsetX / this.sizeCol);
                this.$refs.carousel?.slideTo(slideI);
            }
        },
        created() {
            if (process.client) {
                window.addEventListener('resize', () => {
                    this.pcSlider = !this.$media.md;
                });
            }
        }

    }
</script> -->
<template>
    <hooper class="slider-car" ref="carousel"
            @updated="updatedSize" @mousemove.native.prevent.stop="mousemove"
            :itemsToShow="(pcSlider || full) ? 1 : 1.075" :centerMode="true" :wheelControl="false" :mouseDrag="!pcSlider">
        <slide v-for="(src, i) in list" :key="i">
            <img v-lazy-load :src="src" v-bind="getImgSeo(i)">
            <div class="other-photo" v-if="list.length > maxPhoto && i == maxPhoto-1">
                <img v-lazy-load src="@/assets/img/icons/camera.svg" alt="Другие фотографии">
                <div>Ещё {{images.length - maxPhoto}} фото</div>
            </div>
        </slide>

        <pagination v-show="pcSlider && list.length > 1" slot="hooper-addons"></pagination>
    </hooper>
</template>

<script>

//https://baianat.github.io/hooper/
import { Hooper, Pagination, Slide } from 'hooper';
import { carImgSeo } from '@/utils/seo/carImgSeo.ts';
import { mapGetters } from 'vuex';

export default {
    name: "slider",
    props: {
        maxPhoto: {
            default: 5,
        },
        images: {
            type: Array,
            default: () => [],
        },
        full: Boolean,
        stopImmediate: Boolean,
        car: Object,
    },
        components: {
            Hooper,
            Slide,
            Pagination
        },
        data(){
            return {
                slideWidth: 0,
                pcSlider: !this.$media.md
            }
        },
        watch: {
            pcSlider(){
                this.$refs.carousel?.update();
            }
        },
        computed: {
            list() {
                return this.images.slice(0, this.maxPhoto).map(image => image?.md ?? image);
            },
            sizeCol() {
                return this.slideWidth / Math.min(this.images.length, this.maxPhoto);
            },
            ...mapGetters('glossary', ['currentCity']),
        },
        methods: {
            getImgSeo(index) {
                return carImgSeo(this.car, this.currentCity.title, index);
            },
            updatedSize({ slideWidth }) {
                this.slideWidth = slideWidth;
            },
            mousemove({ offsetX }) {
                const slideI = Math.floor(offsetX / this.sizeCol);
                if (this.$refs.carousel) this.$refs.carousel?.slideTo(slideI);
            },
        },
        created() {
            if (process.client) {
                window.addEventListener('resize', () => {
                    this.pcSlider = !this.$media.md;
                });
            }
        },
        mounted() {
            if(this.stopImmediate && this.$media.md && this.$refs.carousel) {
                this.$refs.carousel?.$refs?.list?.addEventListener('touchstart', (e)=>{
                    e.stopImmediatePropagation();
                });
            }
        }

    }
</script>
<style lang="scss">
    .slider-car {
        position: relative;
        width: auto;
        margin: 0 -15px;
        margin-bottom: 16px;

        &, img {

            height: 180px !important;
        }

        .hooper-slide {

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 4px;
                display: block;
                width: calc(100% - 8px);
                height: 30%;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.4+0,0+100 */
                background: -moz-linear-gradient(top,  rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
                border-radius: 8px;
            }
        }

        @include mobileUp {
            margin: 0;

            &, > img {
                height: 290px !important;
            }
        }
    }
</style>
