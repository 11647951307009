<template>
    <div class="vehicle-card-main-photo" @click="handleClick" :class="{'mobile-horizontal': mobileHorizontal}">
        <ImageHolder :image="carMainImage" :x2="carMainImageX2" :car="car" keep-alive>
            <div class="main-photo-slider" v-if="isSlider">
                <Hooper
                    ref="slider"
                    :settings="mergedSliderSettings"
                    @slide="handleSliderSwipe"
                >
                    <Slide v-for="(image, index) in images" :key="index">
                        <ImageHolder
                            :image="index <= maxSlide ? getImage(image) : blank"
                            :car="car"
                            :index="index"
                        />
                    </Slide>
                    <template #hooper-addons>
                        <Navigation>
                            <template #hooper-prev>
                                <ArrowIcon/>
                            </template>
                            <template #hooper-next>
                                <ArrowIcon/>
                            </template>
                        </Navigation>
                    </template>
                </Hooper>
            </div>
            <div class="card-other-info">
                <slot/>
                <CarsSliderPhotosCount
                    :current="currentSlide"
                    :total="photoCount"
                />
            </div>
        </ImageHolder>
    </div>
</template>

<script>
import noImages from "assets/img/car/car-no-image.jpg";
import noImagesX2 from "assets/img/car/car-no-image@2x.jpg";
import ArrowIcon from "assets/img/icons/car-card-photos-nav-arrow.svg?inline"
import { Hooper, Navigation, Slide } from "hooper";
import ImageHolder from "@/components/_elements/profile/ImageHolder";
import CarsSliderPhotosCount from "@/components/vehicles/CarsSliderPhotosCount";

const defaultSliderSettings = {
    wheelControl: false,
}

export default {
    name: "VehicleCardMainPhoto",
    components: {CarsSliderPhotosCount, ImageHolder, Hooper, Slide, Navigation, ArrowIcon },
    props: {
        car: {
            type: Object,
            required: true,
        },
        sliderSettings: {
            type: Object,
            default: () => ({}),
        },
        mobileHorizontal: Boolean,
    },
    data: () => ({
        sliderMounted: false,
        currentSlide: 1,
        maxSlide: 0,
        blank: '/img/blank.png',
    }),
    computed: {
        mergedSliderSettings() {
            return {
                ...defaultSliderSettings,
                ...this.sliderSettings,
            }
        },
        images() {
            return this.car?.images || [];
        },
        hasPhotos() {
            return this.images.length > 0;
        },
        isSlider() {
            return this.images.length > 1;
        },
        carMainImageX2() {
            if (this.carMainImage === noImages) {
                return noImagesX2;
            }
        },
        carMainImage() {
            if (this.hasPhotos) {
                if (this.isSlider && this.sliderMounted) {
                    return null;
                }
                return this.getImage(this.images[0] || noImages);
            }
            return noImages;
        },
        photoCount() {
            return this.images?.length ?? 1;
        },
    },
    methods: {
        getImage(image) {
            return image?.md ?? image;
        },
        handleClick(event) {
            if (this.isSlider && this.$refs.slider?.isSliding) {
                event.preventDefault();
                event.stopPropagation();
            }
        },
        handleSliderSwipe({currentSlide, slideFrom}) {
            const isInitial = slideFrom == null;
            this.maxSlide = Math.max(this.maxSlide, currentSlide);
            this.currentSlide = currentSlide + 1;
            if (isInitial) {
                this.sliderMounted = true;
            } else if (currentSlide !== slideFrom) {
                this.$signals.swipeCatalogCarSpider(this.car, currentSlide);
            }
        },
    },
}
</script>

<style lang="scss">
.vehicle-card-main-photo {
    position: relative;
    margin-bottom: 6px;

    border-radius: 8px;
    overflow: hidden;

    background-color: $shimmerAccent;

    &, .main-photo-slider {
        height: 220px;

        @include mobile {
            height: 200px;
            //height: calc(100vw * (2 / 3) - 8px); //2:3
        }
    }

    &.mobile-horizontal {
        @include mobile {
            &, .main-photo-slider {
                height: 96px;
            }
        }
    }

    .card-other-info {
        z-index: 2;

        .CarsSliderPhotosCount {
            position: absolute;
            left: 50%;
            bottom: 6px;
            transform: translateX(-50%);
        }
    }

    .main-photo-slider {
        position: relative;
    }

    .hooper {
        position: relative;
        height: 100% !important;

        .hooper-slide {
            padding: 0;
        }

        .hooper-navigation {
            display: block;
            opacity: 0;

            @include mobile {
                opacity: 1;
            }

            transition: opacity 150ms;

            button {
                $offset: 6px;
                width: 32px !important;
                height: 32px !important;
                margin: $offset;
                z-index: 3;

                background: rgba($blackLight, .8);
                box-shadow: none !important;
                backdrop-filter: blur(8px);

                @include mobile {
                    background: rgba($blackLight, .4);
                }

                &:hover {
                    background: $blackLight;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -$offset;
                    bottom: -$offset;
                    left: -$offset;
                    right: -$offset;
                }

                &.hooper-prev {
                    left: 0;

                    svg {
                        transform: scaleX(-1);
                    }
                }

                &.hooper-next {
                    right: 0;
                }

                &.is-disabled {
                    display: block;

                    svg {
                        opacity: 0.48;
                    }
                }
            }
        }
    }
}
</style>
