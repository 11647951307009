<template>
    <div v-if="total && total > 1" class="CarsSliderPhotosCount">
        <div class="CarsSliderPhotosCount__title">
            {{ countTitle }}
        </div>
    </div>
</template>

<script>
export default {
    name: "CarsSliderPhotosCount",
    props: {
        current: Number,
        total: Number,
    },
    computed: {
        countTitle() {
            return `${this.current} / ${this.total}`;
        },
    },
}
</script>

<style lang="scss">
.CarsSliderPhotosCount {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;
    height: 22px;
    padding: 0 10px;

    background: rgba($blackBlue, 0.56);
    border-radius: 12px;

    @include mobile {
        border-radius: 4px;
    }

    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: $white;
    }
}
</style>
