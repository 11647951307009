<template>
    <div class="car-rating-info">
        <ShortRating :rating="value" />
        <div class="car-reviews-label">
            <div class="car-review-count">
                {{ carReviewsCountString }}
            </div>
            <div v-if="!totalReviews && rents" class="car-rents-count">
                {{ carRentsCountString }}
            </div>
        </div>
    </div>
</template>

<script>
import ShortRating from "@/components/cars/_car/ShortRating";
import {declensionReviews, declensionTrip} from "@/utils/word";

export default {
    name: "CarRatingInfo",
    components: { ShortRating },
    inheritAttrs: false,
    props: {
        value: Number,
        short: Boolean,
        rents: Number,
        reviews: Number,
        reviews_with_text: Number,
        reviews_without_text: Number,
    },
    computed: {
        totalReviews() {
            return this.reviews || (this.reviews_with_text + this.reviews_without_text) || 0;
        },
        carReviewsCountString() {
            if (this.totalReviews) {
                return declensionReviews(this.totalReviews);
            } else {
                return 'Новинка';
            }
        },
        carRentsCountString() {
            if (this.rents) {
                return declensionTrip(this.rents);
            }
        },
    },
}
</script>

<style lang="scss">
.car-rating-info {
    display: flex;
    align-items: center;

    .car-reviews-label {
        display: flex;
        align-items: center;

        margin-left: 6px;

        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba($blackBlue, 0.64);
    }

    .car-rents-count {
        position: relative;
        display: inline-flex;
        align-items: center;

        margin-left: 16px;

        &::before {
            content: '';
            position: absolute;
            left: -10px;

            width: 4px;
            height: 4px;

            background: rgba($blackBlue, 0.24);
            border-radius: 50%;
        }
    }
}
</style>
