<template>
    <div class="clear-filters-button" @click="resetFilters()" v-if="countFilters">
        <img v-lazy-load src="@/assets/img/icons/clear-cars-filters.svg" alt="clear">
        <span>Сбросить фильтры</span>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import resetFilters from "@/mixins/filters/resetFilters";

export default {
    name: "cleanFilters",
    mixins: [resetFilters],
    computed: {
        ...mapGetters('cars', ['countFilters'])
    },
}
</script>

<style lang="scss">
.clear-filters-button {
    display: flex;
    align-items: center;

    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $blackBlue;

    cursor: pointer;

    img {
        margin-right: 8px;
    }
}
</style>
