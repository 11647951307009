<template>
    <div class="ShimmerLoaderWrap">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "ShimmerLoaderWrap"
}
</script>

<style lang="scss">
.ShimmerLoaderWrap {
    position: relative;
    pointer-events: none;

    animation: shimmer ease-in-out 1s infinite alternate forwards;

    @keyframes shimmer {
        from {
            opacity: 1;
        }
        to {
            opacity: 0.65;
        }
    }
}
</style>
