<template>
    <div class="mobile-slider">
        <Hooper
            :itemsToShow="1.1"
            :wheelControl="false"
            v-bind="$attrs"
        >
            <Slide
                class="mobile-slider-slide"
                v-for="(item, index) in list"
                :key="index"
                :index="index"
            >
                <slot :item="item"/>
            </Slide>
        </Hooper>
    </div>
</template>

<script>
import {Hooper, Slide} from 'hooper';

export default {
    name: "MobileSlider",
    inheritAttrs: false,
    components: {
        Hooper, Slide,
    },
    props: {
        list: Array,
    }
}
</script>

<style lang="scss">
.mobile-slider {

    .hooper {
        height: 100%;

        .hooper-list {
            margin: 0 -15px;
            padding: 0 15px;
            width: calc(100% + 30px);
        }

        .hooper-slide:not(.is-active) {
            opacity: 1;
        }
    }
}
</style>
