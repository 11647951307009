<template>
    <AccentModal
        class="BonusPointsActivateModal"
        :id="id || $modals.BONUS_POINTS_ACTIVATE"
        :title="title || promotionTitle"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div class="BonusPointsActivateModal__body">
            <slot>
                <div
                    class="BonusPointsActivateModal__description"
                    v-html="description || promotionText"
                />
            </slot>
        </div>
        <button
            class="BonusPointsActivateModal__button primary full"
            :disabled="!active && (disabled || !promotion || !segmentId)"
            @click="handleActiveBonusPointsClick"
        >
            {{ buttonText }}
        </button>
        <div
            v-if="expiringDate || promotionExpiringDate"
            class="BonusPointsActivateModal__expiring-date"
            v-html="expiringDate || promotionExpiringDate"
        />
    </AccentModal>
</template>

<script>
import AccentModal from "@/components/_elements/AccentModal";
import {mapActions, mapMutations} from "vuex";
import {goToAuth} from "@/utils/auth";
import {SIGN_UP_SOURCE} from "@/utils/_consts";

export default {
    name: "BonusPointsActivateModal",
    components: {AccentModal},
    props: {
        id: String,
        open: Boolean,
        promotion: Object,
        segmentId: [Number, String],
        title: String,
        description: String,
        expiringDate: String,
        disabled: Boolean,
        active: Boolean,
        buttonText: {
            type: String,
            default: 'Активировать',
        },
    },
    created() {
        if (this.$route.query.activate && this.$auth.loggedIn && this.segmentId && this.promotion) {
            this.$router.replace({
                ...this.$route,
                query: {
                    activate: undefined,
                }
            }, this.activeBonusPoints, this.activeBonusPoints);
        }
    },
    computed: {
        promotionExpiringDate() {
            if (!this.promotion) {
                return;
            }

            const expiredDate = this.promotion.points_expired_at;
            if (expiredDate) {
                const date = this.$moment.unix(expiredDate).format('DD.MM.YY');
                return `Баллы действуют до ${date}`;
            }
        },
        promotionTitle() {
            return this.promotion?.title;
        },
        promotionText() {
            if (!this.promotion) {
                return;
            }

            const text = this.promotion.text;
            return `${text}<br><b>1 балл = 1 рубль</b>`;
        },
    },
    methods: {
        handleActiveBonusPointsClick() {
            this.$emit('activate');
            if (this.promotion && this.segmentId) {
                this.$signals.bonusPointsPromotionActivateInModal(this.promotion.id);
            } else {
                return;
            }
            if (this.$auth.loggedIn) {
                this.activeBonusPoints();
            } else {
                this.setSourceSignup(SIGN_UP_SOURCE.BONUS_POINTS_PROMO);
                const query = {
                    ...this.$route.query,
                    promotionSegmentId: undefined,
                    activate: this.promotion.id,
                };
                const toSignIn = () => goToAuth(this);
                this.$router.replace({query}, toSignIn, toSignIn);
            }
        },
        activeBonusPoints() {
            this.activatePromotion({
                params: {segmentId: this.segmentId},
            }).then(() => {
                this.$emit('activated');
                this.openModal({
                    name: this.$modals.PAYMENT_BONUS_CONGRATULATIONS,
                    data: {points: this.promotion.points},
                });
            }).catch(() => {
                this.$notify('Ошибка активации балов акции', null, 'warning', null, 5000)
            })
        },
        ...mapMutations(['setSourceSignup']),
        ...mapMutations('modal', ['openModal']),
        ...mapActions('promotions', ['activatePromotion']),
    },
}
</script>

<style lang="scss">
.BonusPointsActivateModal {

    &__body {
        width: 100%;
        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 28px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: $white;
    }

    &__date {
        &:disabled {
            opacity: 0.32;
            background: #5394FD;
            color: $white;
        }
    }

    &__expiring-date {
        margin-top: 6px;

        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: rgba($white, 0.8);
    }

    &__button:disabled {
        &, &:hover {
            background: #5394FD !important;
            color: $white !important;
            opacity: 0.32 !important;
        }
    }
}
</style>
