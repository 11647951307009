<template>
    <div class="car-rating" v-if="ratingCount > 0">
        <div class="rating-box">
            {{ ratingVal }}
        </div>
        <div class="about">
            <div class="text">{{ nameReview }}</div>
            <div class="count">{{ declensionReviews }}</div>
        </div>
    </div>
</template>

<script>
    import {declensionReviews} from "@/utils/word";
    import {RATING_NAME} from "@/utils/_consts";

    export default {
        name: 'car-rating',
        props: {
            rating: Object
        },
        computed:{
            ratingVal() {
                if(!this.rating) return 0;
                return Math.round(this.rating.value);
            },
            ratingCount() {
                const rating = this.rating;
                if(!rating) return 0;
                return Math.round(rating.reviews);
            },
            declensionReviews(){
                return declensionReviews(this.ratingCount);
            },
            nameReview(){
                return RATING_NAME[Object.keys(RATING_NAME).find((ratingMax) => this.ratingVal < ratingMax)];
            }
        }
    }
</script>

<style lang="scss">
    .car-rating {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &.star {
            @include mobile {
                margin-top: 18px;
            }

            .rating-box {
                width: auto;
                height: auto;
                background: transparent;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: $blackBlue;
                margin-right: 16px;

                @include mobileUp {
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px;
                }

                &::before {
                    content: '';
                    background: url("~@/assets/img/icons/rating-star.svg");
                    background-size: 100% 100%;
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;

                    @include mobileUp {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .about {
                display: block;

                .text {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;

                    @include mobileUp {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }

                .count {
                    font-size: 12px;
                    line-height: 16px;

                    @include mobile {
                        display: none;
                    }
                }
            }
        }


        &.small-mobile {
            margin-left: 32px;

            @include mobile {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                height: 24px;
                padding: 4px 8px;
                background: $secondary;
                border-radius: 50px;

                &::after {
                    content: url("~@/assets/img/icons/star-white.svg");
                    line-height: 8px;
                    margin-left: 4px;
                }
            }
        }

        .about {
            text-align: left;

            @include mobile {
                display: none;
            }

            .text {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
            }

            .count {
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: $grayText;
            }
        }

        &.show {
            @include mobile {
                order: -1;
                margin-bottom: 16px;

                .about {
                    display: block;
                }
            }
        }

        .rating-box {
            @include flexCenter(flex);
            width: 40px;
            height: 40px;

            color: $white;
            font-size: 16px;
            line-height: 28px;
            font-weight: bold;
            background-color: $secondary;
            border-radius: 50%;

            margin-right: 12px;

            flex-shrink: 0;
        }

        &.small-mobile {

            .rating-box {
                border-radius: 50%;

               @include mobile {
                   background: none;
                   width: auto;
                   height: auto;
                   font-size: 12px;
                   line-height: 16px;
                   margin: 0;
               }
            }
        }
    }
</style>
