import {getUtm} from "@/utils/router";

export function auth({$auth, $signals}, {username, password}, confirmedAudi = false) {
    const data = {
        username,
        password,
        grant_type: "password",
        client_id: process.env.VUE_APP_API_CLIENT_ID || 2,
        client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
        source_info: getUtm(),
    };

    if(confirmedAudi) {
        data.client_info = {
            supplied_audi: 1
        };
    }
    return $auth.loginWith('local', {data}).then(() => {
        $signals.login('password');
    })
}

export function goToAuth(self) {
    self.$auth.$storage.setUniversal('redirect', self.$route.fullPath)
    self.$router.push({name: 'sign-in'});
}
