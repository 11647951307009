<template>
    <div class="our-advantages-module">
        <module-title title="Наши преимущества" />
        <div class="our-advantages-module__list">
            <client-only>
                <template v-if="!$media.md">
                    <OurAdvantagesItemModule
                        v-for="(item, index) in list"
                        :key="index"
                        v-bind="item"
                    />
                </template>
                <MobileSlider v-else :list="list">
                    <template #default="{item: item, index}">
                        <OurAdvantagesItemModule
                            :key="index"
                            v-bind="item"
                        />
                    </template>
                </MobileSlider>
            </client-only>
        </div>
    </div>
</template>

<script>
import ModuleTitle from "@/components/mainPage/ModuleTitle";
import OurAdvantagesItemModule from '@/components/mainPage/modules/advantages/OurAdvantagesItemModule';
import WalletIcon from '@/assets/img/icons/wallet.svg';
import GlobeIcon from '@/assets/img/icons/globe.svg';
import WalletPlusIcon from '@/assets/img/icons/wallet-plus.svg';
import GiftIcon from '@/assets/img/icons/gift.svg';
import ThumbsUpIcon from '@/assets/img/icons/thumbs-up.svg';
import SecurityIcon from '@/assets/img/icons/ic-security.svg';
import FileTextIcon from '@/assets/img/icons/file-text.svg';
import MobileSlider from '@/components/mainPage/modules/MobileSlider';

const _item = (title, about, icon) => ({ title, about, icon });

export default {
    name: "OurAdvantagesModule",
    components: { MobileSlider, OurAdvantagesItemModule, ModuleTitle },
    data: () => ({
        list: [
            _item(
                'Экономьте до&nbsp;50% на&nbsp;дальних поездках',
                'На&nbsp;длинные поездки большинство собственников предоставляют хорошие скидки',
                WalletIcon,
            ),
            _item(
                'Путешествуйте по&nbsp;всей России без ограничений',
                'В&nbsp;отличии от&nbsp;каршеринга, наши собственники готовы отпустить свои автомобили в&nbsp;самые дальние края',
                GlobeIcon,
            ),
            _item(
                'Бесплатная отмена за&nbsp;48&nbsp;часов',
                'Полный возврат средств можно сделать бесплатно отменив бронирование за&nbsp;2&nbsp;суток до&nbsp;начала аренды',
                WalletPlusIcon,
            ),
            _item(
                'Бонусная программа',
                'С каждой аренды вам вернется 1% кэшбэка баллами, которые вы сможете потратить на будущие поездки',
                GiftIcon,
            ),

            _item(
                'Более 2000 автомобилей на&nbsp;любой вкус и&nbsp;кошелек',
                'В&nbsp;каталоге представлены минивэны, недорогие седаны, кроссоверы, бизнес и&nbsp;ретро автомобили',
                ThumbsUpIcon,
            ),
            _item(
                'Все авто застрахованы',
                'Автомобили застрахованы по&nbsp;ОСАГО',
                SecurityIcon,
            ),
            _item(
                'Электронный документооборот',
                'Никаких бумажных актов и&nbsp;договоров аренды, все формируется электронно в&nbsp;удобном приложении',
                FileTextIcon,
            ),
        ],
    }),
}
</script>

<style lang="scss">
.our-advantages-module {

    &__list {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 24px;

        @include tablet {
            & > * {
                grid-column: 4 span;
            }
        }

        @include mobile {
            display: block;
            grid-gap: unset;
        }

        @include tabletUp {
            & > * {
                grid-column: 3 span;

                &:nth-child(n+5) {
                    grid-column: 4 span;
                }
            }
        }
    }
}
</style>
