<template>
    <div class="main-page-filters">
        <div class="filters-list">
            <term-filter-block />
            <div class="filter-double-block">
                <client-only>
                    <transmission-filter-block class="filter-half-block" :show="$media.md" />
                    <cars-body-filter-block class="filter-half-block" :show="$media.md" />
                </client-only>
            </div>
        </div>
        <button class="primary filter-action-button" @click="goToCars">
            Найти автомобиль
        </button>
    </div>
</template>

<script>
import { goToCars } from "@/utils/router";
import TermFilterBlock from "@/components/mainPage/modules/filter/filterBlocks/TermFilterBlock";
import TransmissionFilterBlock from "@/components/mainPage/modules/filter/filterBlocks/TransmissionFilterBlock";
import CarsBodyFilterBlock from "@/components/mainPage/modules/filter/filterBlocks/CarsBodyFilterBlock";

export default {
    name: "MainPageFilters",
    components: {
        CarsBodyFilterBlock,
        TransmissionFilterBlock,
        TermFilterBlock,
    },
    methods: {
        goToCars() {
            return goToCars(this.$route.query);
        },
    },
};
</script>

<style lang="scss">
.main-page-filters {
    display: grid;

    @include mobileUp {
        grid-template-columns: 1fr 23.5%;
        grid-gap: 16px;
    }

    @include tablet {
        grid-gap: 12px;
        grid-template-columns: 1fr;
    }

    .filters-list {
        display: grid;

        @include mobileUp {
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
        }

        @include tablet {
            grid-gap: 12px;
            grid-template-columns: 1fr;
        }

        @include mobile {
            grid-gap: 2px;
            border-radius: 8px;
            overflow: hidden;
        }

        .filter-double-block {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @include mobile {
                grid-gap: 2px;
            }
        }

        .filter-left-side-block {

            @include mobileUp {
                border-radius: 10px 0 0 10px;
            }
        }

        .filter-right-side-block {

            @include mobileUp {
                border-radius: 0 10px 10px 0;
                border-left: solid 1px #E5E8EC;
            }
        }

        .filter-single-block {
            @include mobileUp {
                border-radius: 10px;
            }
        }
    }

    .filter-action-button {
        height: 48px;

        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        @include mobileUp {
            height: 60px;
            padding: 0 20px;

            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.01em;
        }
    }
}
</style>
