<template>
    <label v-if="image || keepAlive" class="image-holder-wrap">
        <template v-if="image">
            <span
                v-if="false"
                class="image-holder"
                v-lazy-background :lazy-background="this.imgSrc"
            />
            <img v-lazy-load
                class="image"
                v-bind="imgSeo"
                :src="imgSrc"
                :srcset="srcset"
                :style="transformStyle"
                @error="handleError"
                :loading="notLazy ? 'eager' : 'lazy'"
            />
        </template>
        <span v-if="$slots.default" class="image-holder-body">
            <slot />
        </span>
    </label>
</template>

<script>
import { carImgSeo } from '@/utils/seo/carImgSeo.ts';
import { mapGetters } from 'vuex';

export default {
    name: "ImageHolder",
    props: {
        image: {},
        x2: String,
        car: Object,
        notLazy: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        rotate: {
            type: Number,
            required: false,
        },
        keepAlive: Boolean,
        detailed: Boolean,
    },
    data: () => ({
        tryIndex: 0,
        timeout: null,
    }),
    beforeDestroy() {
        clearTimeout(this.timeout);
    },
    computed: {
        imgSeo() {
            return carImgSeo(this.car, this.currentCity.title, this.index, this.detailed);
        },
        isUrlImg() {
            return typeof this.image === 'string';
        },
        imgSrc() {
            return this.isUrlImg ? this.wrapImageUrl(this.image) : this.getUrl(this.image);
        },
        transformStyle() {
            if (this.rotate) {
                return {
                    transform: `rotate(${this.rotate}deg)`,
                }
            }
        },
        srcset() {
            if (this.x2) {
                return `${this.x2} 2x`;
            }
        },
        ...mapGetters('glossary', ['currentCity']),
    },
    methods: {
        getUrl(file) {
            if (process.client) {
                try {
                    return URL.createObjectURL(file);
                } catch (e) {
                    console.warn(e);
                }
            }
        },
        wrapImageUrl(image) {
            if (this.tryIndex) {
                try {
                    const url = new URL(image);
                    url.searchParams.append('try', this.tryIndex);
                    return url.toString();
                } catch (e) {
                    console.warn(e);
                    return image;
                }
            } else {
                return image;
            }
        },
        handleError() {
            this.timeout = setTimeout(() => {
                if (this.tryIndex < 5) {
                    this.tryIndex++;
                }
            }, 2000);
        },
    },
}
</script>

<style lang="scss">
.image-holder-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;
    z-index: 1;

    .image-holder {
        $offset: -16px;
        position: absolute;
        left: $offset;
        right: $offset;
        top: $offset;
        bottom: $offset;

        display: flex;
        z-index: -1;

        background-size: cover;
        background-position: center;
        filter: blur(30px);

        pointer-events: none;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background: rgba($blackBlue, 0.24);
        }
    }

    .image {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    .image-holder-body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
</style>
