<template>
    <div class="car-list">
        <slot></slot>

        <car
            v-for="(car, i) in list" :key="i"
            :car="car"
            :period="period"
            :openWindow="openWindow"
            :force-brand-alias="forceBrandAlias"
        />

        <pagination v-bind="meta" @next="nextPage" :disabled="pendingCars" v-if="pagination" />

        <!-- <img v-lazy-load v-if="pending" src="/images/icons/loading.svg" @click="nextPage" class="loading"> -->
    </div>
</template>

<script>
import car from "@/components/cars/_car/car";
import { mapActions, mapMutations, mapState } from "vuex";
import Pagination from "@/components/_elements/pagination";
import _isEqual from "lodash/isEqual";

export default {
    name: 'car-list',
    props: {
        carsList: Array,
        pagination: Boolean,
        filtersCustom: {
            default: () => ({}),
        },
        openWindow: Boolean,
        exclude: {
            default: false,
        },
        methodGetCars: {},
        period: Object,
        forceBrandAlias: String,
    },
    components: {
        Pagination,
        car,
    },
    mounted() {
        if (process.client && !this.carsList && !this.pagination && !this.pendingCars) {
            this.updateCars();
        }
    },
    computed: {
        empty() {
            return !this.list?.length;
        },
        list() {
            if (this.carsList) return this.carsList;
            else return this.cars.list;
        },
        selectionAlias() {
            return this.$route.params.selection;
        },
        meta() {
            const { list, ...meta } = this.cars;
            return meta;
        },
        pendingCars() {
            return this.$store.state.cars.pending?.cars;
        },
        ...mapState('cars', ['cars', 'sort', 'filters', 'error']),
    },
    methods: {
        updateCars() {
            this.getCars({
                selection: this.selectionAlias,
                city: this.filtersCustom?.city,
                filter: this.filtersCustom,
            }).catch((e) =>
                this.$axiosError(this.$notifySrvErr('Ошибка получения списка автомобилей!', e)),
            );
        },
        getCars(filters, ...params) {
            if (this.exclude) filters.exclude = this.exclude;
            if (this.methodGetCars === false) return Promise.resolve();
            else if (this.methodGetCars) return this.methodGetCars(filters, ...params);
            else return this._getCars(filters, ...params);
        },
        nextPage() {
            this.$signals.searchClickShowMore();
            const page = this.cars.currentPage + 1;
            const scroll = process.client && window.scrollY;
            let unWatch;
            unWatch = this.$watch('pendingCars', (pending) => {
                if (!pending) {
                    unWatch();
                    if (process.client) {
                        this.$nextTick(() => {
                            window.scroll(0, scroll);
                        });
                    }
                }
            });
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    page,
                    infinite: true,
                },
            });
        },
        ...mapActions('cars', { _getCars: 'getCars' }),
        ...mapMutations('cars', ['setFilter', 'carsReset']),
    },
    watch: {
        /* PROPS */
        filtersCustom: {
            deep: true,
            immediate: true,
            handler(filters, oldFilters) {
                if (this.exclude) return;
                if (this.carsList) return;
                if (!this.pagination && this.cars.list.length) this.carsReset();
                if (!filters) return;
                if (_isEqual(filters, oldFilters)) return;
                Object.keys(filters).forEach(name => {
                    this.setFilter({ name, val: filters[name] });
                });
            },
        },
    },
}

</script>

<style lang="scss">
.car-list {

    @include mobileUp {
        position: relative;
    }

    .car-block {
        .title-car {
            @include mobile {
                padding-right: 45px;
            }
        }
    }

    .infinite-loading-container {
        font-size: 14px;
        color: $grayText;

        @include mobileUp {
            font-size: 18px;
        }

        .infinite-status-prompt:not(.hide) {
            padding: 16px;
        }
    }

    .infinite-spinner {
        margin: 0 auto;
        display: block;
        cursor: pointer;


        img {
            animation: car-list-loading 1s steps(8, end) infinite;
            margin-right: 12px;

            @include mobileUp {
                margin-right: 16px;
            }
        }
    }

    @keyframes car-list-loading {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
</style>
