<template>
    <filter-item-modal-holder filter-name="cars_body" :show="show">
        <template #header>
            <filter-item-header
                class="filter-right-side-block"
                :placeholder="!$media.md ? 'Кузов автомобиля' : 'Кузов'"
                :value="value"
            />
        </template>
        <template #body>
            <client-only>
                <filter-list
                    glossary="cars_body"
                    title="Выберите тип кузова"
                    v-if="!$media.md"
                />
                <filter-list-mobile glossary="cars_body" v-else />
            </client-only>
        </template>
    </filter-item-modal-holder>
</template>

<script>
import FilterItemHeader from "@/components/mainPage/modules/filter/FilterItemHeader";
import FilterItemModalHolder from "@/components/mainPage/modules/filter/FilterItemModalHolder";
import FilterList from "@/components/search/filters/types/filterList";
import FilterListMobile from "@/components/search/filters/types/filterListMobile";
import { mapGetters, mapState } from 'vuex';

export default {
    name: "CarsBodyFilterBlock",
    components: { FilterListMobile, FilterList, FilterItemModalHolder, FilterItemHeader },
    props: {
        show: Boolean,
    },
    computed: {
        value() {
            const val = this.filters.cars_body;
            if (val) {
                return this.getGlossaryOption("cars_body", val)?.title;
            }
        },
        ...mapGetters("glossary", ["getGlossaryOption"]),
        ...mapState("cars", ["filters"]),
    },
}
</script>

<style lang="scss">
.cars-body-filter-block {

}
</style>
