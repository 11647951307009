<template>
    <h2 class="module-title">
        <span v-if="tag" class="module-title-tag">
            {{ tag }}
        </span>
        <span>
            {{ title }}
        </span>
    </h2>
</template>

<script>
export default {
    name: "ModuleTitle",
    props: {
        title: String,
        tag: String,
    }
}
</script>

<style lang="scss">
.module-title {
    display: flex;
    align-items: center;

    margin-bottom: 16px;

    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: $blackBlue;

    @include mobileUp {
        margin-bottom: 24px;

        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
    }

    .module-title-tag {
        padding: 6px 10px;
        margin-right: 12px;

        background: #172335;
        border-radius: 4px;

        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #FFFFFF;

        @include mobile {
            margin-right: 8px;
            padding: 4px 8px;
        }
    }
}
</style>
