<template>
    <div v-if="pages > 1" class="pagination">
        <div
            v-if="more"
            class="show-more-button"
            :class="{disabled: nextPageDisabled}"
            @click.prevent="nextPageInfinite"
        >
            Показать ещё
        </div>
        <PaginationNumber
            v-if="showPages"
            :max="pages"
            :active="currentPage"
            :route="route"
        />
    </div>
</template>

<script>
import PaginationNumber from "@/components/_elements/paginationNumber";

export default {
    name: "pagination",
    components: {PaginationNumber},
    props: {
        currentPage: Number,
        pages: Number,
        disabled: Boolean,
        more: {
            type: Boolean,
            default: true,
        },
        showPages: {
            type: Boolean,
            default: true,
        },
        route: String,
    },
    computed: {
        prevPage() {
            if (this.currentPage > 1) return this.currentPage - 1;
        },
        nextPage() {
            if (this.currentPage < this.pages) return this.currentPage + 1;
        },
        nextPageDisabled() {
            return !this.nextPage;
        },
    },
    methods: {
        nextPageInfinite() {
            if (!this.nextPageDisabled) {
                this.$emit('next');
            }
        }
    }
}
</script>

<style lang="scss">
.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;

    .show-more-button {
        margin-bottom: 32px;
        padding-right: 36px;

        background-image: url("~@/assets/img/icons/car-show-more-arrow.svg");
        background-position: right 50%;
        background-size: 24px;
        background-repeat: no-repeat;

        font-weight: normal;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: $blackBlue;

        cursor: pointer;

        @include mobile {
            margin-bottom: 20px;

            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.01em;
        }

        &.disabled {
            cursor: default;
            opacity: 0.5;
        }
    }
}
</style>
