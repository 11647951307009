<template>
    <BonusPointsActivateModal
        class="BonusPointsExpiredPromotionModal"
        :id="$modals.BONUS_POINTS_EXPIRED_PROMOTION"
        title="Упс  =("
        description="Акция уже закончилась!"
        disabled
        v-on="$listeners"
    />
</template>

<script>
import BonusPointsActivateModal from "@/components/_elements/BonusPointsActivateModal";

export default {
    name: "BonusPointsExpiredPromotionModal",
    components: {BonusPointsActivateModal},
}
</script>

<style lang="scss">
.BonusPointsExpiredPromotionModal {

}
</style>
