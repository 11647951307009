<template>
    <nuxt-img
        v-bind="{...$attrs, ...lazyAttr}"
        :src="src"
        :srcset="srcset"
        :format="format"
        :preload="preload"
    />
</template>

<script>
import { getFormatImg } from '@/utils/browser/getFormatImg';

export default {
    name: "webp-img",
    props: {
        src: String,
        x2: String,
        preload: Boolean,
        lazy: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        lazyAttr() {
            if (this.lazy) {
                return { loading: 'lazy' };
            } else {
                return {};
            }
        },
        format() {
            return getFormatImg(this.$store);
        },
        srcset() {
            if (this.x2) {
                const src = this.$img(this.x2, { format: this.format });
                return `${src} 2x`;
            }
        },
    },
}
</script>
